import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BloodForm from './BloodForm';

const Bloods = () => {
    const [bloods, setBloods] = useState([]);
    const [bloodTypes, setBloodTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [filteredBloods, setFilteredBloods] = useState([]);
    const [completedBloods, setCompletedBloods] = useState([]);
    const [selectedBlood, setSelectedBlood] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [userRole, setUserRole] = useState(null); // State for user role

    useEffect(() => {
        fetchBloods();
        fetchUserRole(); // Fetch user role on component mount
    }, []);

    const fetchUserRole = () => {
        const role = localStorage.getItem('role'); // Assuming role is stored in local storage
        setUserRole(role);
    };

    useEffect(() => {
        const types = [...new Set(bloods.map(blood => blood.bloodType))];
        setBloodTypes(types);
    }, [bloods]);

    useEffect(() => {
        if (selectedType) {
            const filtered = bloods.filter(blood => blood.bloodType === selectedType && 
                (blood.bagID.toLowerCase().includes(searchTerm.toLowerCase()) ||
                blood.bloodType.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredBloods(filtered);
            setCompletedBloods(filtered.filter(blood => blood.status === 'completed'));
        } else {
            setFilteredBloods([]);
            setCompletedBloods([]);
        }
    }, [searchTerm, selectedType, bloods]);

    const fetchBloods = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bloods`);
            const sortedBloods = response.data.sort((a, b) => {
                const aExpDate = Math.min(...a.components.map(comp => new Date(comp.expirationDate)));
                const bExpDate = Math.min(...b.components.map(comp => new Date(comp.expirationDate)));
                return aExpDate - bExpDate; // Sort by nearest expiration date
            });
            setBloods(sortedBloods);
        } catch (error) {
            console.error("Error fetching bloods:", error);
        }
    };

    const handleRowClick = (blood) => {
        setSelectedBlood(blood);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBlood(null);
    };
    const handleDeleteBlood = async (bagID) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this blood entry?");
        if (confirmDelete) {
            try {
                const adminUsername = localStorage.getItem('username'); // Retrieve the admin username
                await axios.delete(`${process.env.REACT_APP_API_URL}/bloods/${bagID}`, {
                    data: { adminUsername } // Send admin username in the request body
                });
                fetchBloods(); // Refresh the blood list
            } catch (error) {
                console.error("Error deleting blood:", error);
            }
        }
    };
    

    const indexOfLastBlood = currentPage * itemsPerPage;
    const indexOfFirstBlood = indexOfLastBlood - itemsPerPage;
    const currentBloods = filteredBloods.slice(indexOfFirstBlood, indexOfLastBlood);
    const totalPages = Math.ceil(filteredBloods.length / itemsPerPage);

    const isExpiringSoon = (expirationDate) => {
        const today = new Date();
        const sevenDaysFromNow = new Date(today);
        sevenDaysFromNow.setDate(today.getDate() + 7);
        return new Date(expirationDate) <= sevenDaysFromNow;
    };

    return (
        <div className="container mx-auto p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-center text-red-600 mt-5">Blood Inventory</h1>

            <Link to="/add-blood" className="block text-center mt-5">
                <button className="bg-red-600 text-white py-3 px-6 rounded-full shadow-lg hover:bg-red-500 transition">
                    Add Blood
                </button>
            </Link>

            <h2 className="text-3xl mt-8 text-gray-800">Select Blood Type</h2>
            <div className="flex flex-wrap justify-center space-x-4 mb-6">
                {bloodTypes.map((type, index) => (
                    <button 
                        key={index} 
                        className={`py-2 px-4 rounded-lg ${selectedType === type ? 'bg-red-600 text-white' : 'bg-gray-200'} transition duration-200`}
                        onClick={() => setSelectedType(type === selectedType ? '' : type)}
                    >
                        {type}
                    </button>
                ))}
            </div>

            {selectedType && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Blood List for {selectedType}</h2>
                    <input 
                        type="text" 
                        placeholder="Search by Bag ID"
                        className="border rounded-lg p-3 mb-5 w-full shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    <h3 className="text-2xl mt-6 text-gray-800">Available Blood</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                            <thead>
                                <tr className="bg-red-600 text-white">
                                    <th className="py-4 px-6 border-b text-left">Bag ID</th>
                                    <th className="py-4 px-6 border-b text-left">Volume (ml)</th>
                                    <th className="py-4 px-6 border-b text-left">Collection Date</th>
                                    <th className="py-4 px-6 border-b text-left">Status</th>
                                    <th className="py-4 px-6 border-b text-left">Components</th>
                                    {userRole === 'superadmin' && ( // Conditionally render the Actions header
                                        <th className="py-4 px-6 border-b text-left">Actions</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {currentBloods.filter(blood => blood.status !== 'completed').map(blood => (
                                    <tr key={blood.bagID} className="hover:bg-gray-100">
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{blood.bagID}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{blood.volume}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{new Date(blood.collectionDate).toLocaleDateString()}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{blood.status}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">
                                            <ul className="list-none">
                                                {blood.components.map((component, index) => (
                                                    <li key={index} className={isExpiringSoon(component.expirationDate) ? 'text-red-600' : ''}>
                                                        <span className="font-semibold">{component.name}: </span>
                                                     Expires on {new Date(component.expirationDate).toLocaleDateString()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                        {userRole === 'superadmin' && ( // Conditionally render the Actions column
                                            <td className="py-4 px-6 border-b text-left">
                                                <button 
                                                    onClick={() => handleRowClick(blood)} 
                                                    className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition"
                                                >
                                                    Edit
                                                </button>
                                                <button 
                                                    onClick={() => handleDeleteBlood(blood.bagID)} 
                                                    className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition ml-2"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <h3 className="text-2xl mt-6 text-gray-800">Completed Blood</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                            <thead>
                                <tr className="bg-gray-300 text-gray-700">
                                    <th className="py-4 px-6 border-b text-left">Bag ID</th>
                                    <th className="py-4 px-6 border-b text-left">Volume (ml)</th>
                                    <th className="py-4 px-6 border-b text-left">Collection Date</th>
                                    <th className="py-4 px-6 border-b text-left">Status</th>
                                    <th className="py-4 px-6 border-b text-left">Components</th>
                                </tr>
                            </thead>
                            <tbody>
                                {completedBloods.map(blood => (
                                    <tr key={blood.bagID} className="hover:bg-gray-100">
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{blood.bagID}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{blood.volume}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{new Date(blood.collectionDate).toLocaleDateString()}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">{blood.status}</td>
                                        <td className="py-4 px-6 border-b text-left text-gray-700">
                                            <ul className="list-none">
                                                {blood.components.map((component, index) => (
                                                    <li key={index} className={isExpiringSoon(component.expirationDate) ? 'text-red-600' : ''}>
                                                        <span className="font-semibold">{component.name}: </span>
                                                        {component.volume} ml, Expires on {new Date(component.expirationDate).toLocaleDateString()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-4 flex justify-between items-center">
                        <button 
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="bg-gray-300 p-2 rounded-lg shadow-md disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <span className="text-lg text-gray-800">Page {currentPage} of {totalPages}</span>
                        <button 
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className="bg-gray-300 p-2 rounded-lg shadow-md disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {isModalOpen && selectedBlood && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <h2 className="text-3xl font-bold mb-4 text-red-600">{selectedBlood.bagID}</h2>
                        <BloodForm bloodId={selectedBlood.bagID} onClose={closeModal} />
                        <button onClick={closeModal} className="mt-4 bg-red-600 text-white py-2 px-4 rounded-full shadow-lg hover:bg-red-500 transition">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Bloods;
