import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

// Register chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BloodInventoryChart = () => {
  // Updated data for 2024 months
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Corrected demand data for 2024
  const demandData2024 = [533, 515, 470, 470, 425, 404, 430, 433, 445, 476, 540, 550];

  // Predicted order data for 2025
  const predictedDemandData2025 = [
    528.304, 536.62, 544.936, 553.252, 561.568, 569.884, 578.2,
    586.516, 594.832, 603.148, 611.464, 619.78
  ];

  // Supply data for 2024 for narrative report
  const supplyData2024 = [405, 408, 429, 435, 420, 441, 550, 489, 433, 436, 422, 400];

  // Prepare data for Chart.js
  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Blood Orders (Demand) 2024',
        data: demandData2024,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.1,
      },
      {
        label: 'Predicted Blood Orders (Demand) 2025',
        data: predictedDemandData2025,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Blood Orders (Demand) 2024 vs Predicted Orders (Demand) 2025',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    },
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Blood Orders Statistics</h1>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <Line data={chartData} options={chartOptions} />

        {/* Explanation Section */}
        <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-sm">
          <h3 className="text-xl font-semibold mb-4">Narrative Report </h3>




          <p className="text-gray-700 mt-4">
            <strong>1. Demand Growth</strong>
          </p>
          <p className="text-gray-700">
            The slope of the regression line (m=8.316) indicates that blood demand increases by approximately 8.32 units per month. This steady rise suggests that factors such as population growth, healthcare needs, or seasonal trends may be contributing to higher demand for blood.
          </p>

          <p className="text-gray-700 mt-4">
            <strong>2. Future Demand Insights</strong>
          </p>
          <p className="text-gray-700">
            From the prediction:
          </p>
          <p className="text-gray-700">
            The demand starts at 529 units in January (next year) and increases steadily, reaching around 620 units in December (next year). Although the growth rate is not drastic, it indicates that healthcare facilities and blood banks should prepare for an average increase of 100 units across the year, compared to the starting months of the current data.
          </p>
          


          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>






          <p className="text-gray-700">
            Here’s a monthly analysis of the blood supply, demand, and shortages/oversupply based on year 2024 data:
          </p>
          
          {months.map((month, index) => (
            <div key={index} className="mt-4">
              <p className="text-gray-700">
                <strong>{month}:</strong>
                <br />
                Supply: {supplyData2024[index]} units
                <br />
                Demand: {demandData2024[index]} units
                <br />
                {supplyData2024[index] > demandData2024[index] ? (
                  <span>Oversupply: +{supplyData2024[index] - demandData2024[index]} units</span>
                ) : (
                  <span>Shortage: -{demandData2024[index] - supplyData2024[index]} units</span>
                )}
                <br />
                {index === 0 && (
                  <span>January experiences the most significant shortage of the year, highlighting the critical need for more donations during this month.</span>
                )}
                {index === 1 && (
                  <span>While slightly better than January, February still shows a substantial deficit in meeting blood demand.</span>
                )}
                {index === 2 && (
                  <span>March marks a noticeable improvement, with the gap narrowing compared to earlier months.</span>
                )}
                {index === 3 && (
                  <span>Shortages continue to decrease, suggesting improved donation efforts or reduced demand fluctuations.</span>
                )}
                {index === 4 && (
                  <span>A near balance between supply and demand is achieved, marking May as a stable month.</span>
                )}
                {index === 5 && (
                  <span>For the first time, supply exceeds demand, reflecting effective blood donation campaigns.</span>
                )}
                {index === 6 && (
                  <span>July sees the highest oversupply of the year, providing a buffer for future shortages.</span>
                )}
                {index === 7 && (
                  <span>August continues the trend of sufficient supply, helping to address potential shortages in subsequent months.</span>
                )}
                {index === 8 && (
                  <span>A minor shortage is recorded, indicating slightly increased demand relative to supply.</span>
                )}
                {index === 9 && (
                  <span>The supply falls short again as demand picks up, mirroring trends earlier in the year.</span>
                )}
                {index === 10 && (
                  <span>November experiences a significant shortage, emphasizing the need for greater efforts to boost blood collection.</span>
                )}
                {index === 11 && (
                  <span>December marks the highest demand and greatest shortage of the year, potentially linked to holiday-related emergencies.</span>
                )}
              </p>
            </div>
          ))}

  
       
        </div>
      </div>
    </div>
  );
};

export default BloodInventoryChart;
