import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const bloodTypes = [
    'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'
];

const bloodComponents = [
    { name: 'Red Cells', hasExpiration: true },
    { name: 'Platelets', hasExpiration: true },
    { name: 'Plasma', hasExpiration: true },
    { name: 'Cryo', hasExpiration: true },
    { name: 'White Cells & Granulocytes', hasExpiration: true }
];

// Function to generate bagID
const generateBagID = () => {
    const now = new Date();
    const yy = String(now.getFullYear()).slice(2); // Last two digits of year
    const mm = String(now.getMonth() + 1).padStart(2, '0'); // Month (01-12)
    const dd = String(now.getDate()).padStart(2, '0'); // Day (01-31)
    const hh = String(now.getHours()).padStart(2, '0'); // Hours (00-23)
    const mi = String(now.getMinutes()).padStart(2, '0'); // Minutes (00-59)
    const ss = String(now.getSeconds()).padStart(2, '0'); // Seconds (00-59)
    return `${yy}${mm}${dd}-${hh}${mi}${ss}`;
};

const AddBlood = () => {
    const [formData, setFormData] = useState({
        bagID: generateBagID(), // Initialize with generated bagID
        bloodType: '',
        collectionDate: '',
        wholeBloodVolume: '',
        quantity: 1,
        status: 'available',
        components: bloodComponents.map(component => ({
            ...component,
            included: false,
            expirationDate: '',
            volume: ''
        }))
    });

    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('expiration_')) {
            const index = parseInt(name.split('_')[1], 10);
            const newComponents = [...formData.components];
            newComponents[index].expirationDate = value;
            setFormData(prev => ({ ...prev, components: newComponents }));
        } else if (name.startsWith('volume_')) {
            const index = parseInt(name.split('_')[1], 10);
            const newComponents = [...formData.components];
            newComponents[index].volume = value;
            setFormData(prev => ({ ...prev, components: newComponents }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleCheckboxChange = (index) => {
        const newComponents = [...formData.components];
        newComponents[index].included = !newComponents[index].included;
        setFormData(prev => ({ ...prev, components: newComponents }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const adminUsername = localStorage.getItem('username');
            const componentsToSubmit = formData.components.filter(component => component.included);
            const dataToSubmit = { 
                ...formData, 
                components: componentsToSubmit,
                volume: formData.wholeBloodVolume,
                adminUsername
            };
            await axios.post(`${process.env.REACT_APP_API_URL}/bloods`, dataToSubmit);
            navigate('/bloods');
        } catch (error) {
            setErrorMessage("Error creating blood record: " + (error.response ? error.response.data : error.message));
            console.error("Error creating blood record:", error);
        }
    };

    return (
        <div className="bg-gray-100 p-6 flex items-center justify-center min-h-screen">
            <div className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full">
                <h1 className="text-3xl font-bold text-center mt-5 text-red-600">Add Blood</h1>
                {errorMessage && (
                    <div className="text-red-500 text-center mb-4">{errorMessage}</div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Bag ID</label>
                        <input
                            type="text"
                            name="bagID"
                            value={formData.bagID}
                            onChange={handleChange} // Allow editing
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Blood Type</label>
                        <select name="bloodType" value={formData.bloodType} onChange={handleChange} required className="w-full p-2 border border-gray-300 rounded">
                            <option value="">Select Blood Type</option>
                            {bloodTypes.map((type) => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Collection Date</label>
                        <input type="date" name="collectionDate" value={formData.collectionDate} onChange={handleChange} required className="w-full p-2 border border-gray-300 rounded" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Whole Blood Volume (ml)</label>
                        <input
                            type="number"
                            name="wholeBloodVolume"
                            placeholder="Volume (ml)"
                            value={formData.wholeBloodVolume}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Quantity</label>
                        <input
                            type="number"
                            name="quantity"
                            placeholder="Quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            min="1"
                            required
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Status</label>
                        <select name="status" value={formData.status} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded">
                            <option value="available">Available</option>
                            <option value="reserved">Reserved</option>
                            <option value="expired">Expired</option>
                        </select>
                    </div>

                    <h2 className="text-lg font-semibold mt-6">Blood Components</h2>
                    {formData.components.map((component, index) => (
                        <div key={index} className="mb-4 border p-4 rounded">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={component.included}
                                    onChange={() => handleCheckboxChange(index)}
                                    className="mr-2"
                                />
                                {component.name}
                            </label>
                            {component.included && (
                                <>
                                    <label className="block text-gray-700 mt-2">Volume (ml)</label>
                                    <input
                                        type="number"
                                        name={`volume_${index}`}
                                        placeholder="Volume (ml)"
                                        value={component.volume}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border border-gray-300 rounded"
                                    />
                                    {component.hasExpiration && (
                                        <>
                                            <label className="block text-gray-700 mt-2">Expiration Date</label>
                                            <input
                                                type="date"
                                                name={`expiration_${index}`}
                                                value={component.expirationDate}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-300 rounded"
                                                required
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    ))}

                    <button type="submit" className="mt-4 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition w-full">Add Blood</button>
                </form>
            </div>
        </div>
    );
};

export default AddBlood;
