import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BloodSelectionModal from './BloodSelectionModal'; // Import your modal

const OrderForm = ({ order, bloods, onClose }) => {
    const [formData, setFormData] = useState({
        bagID: '',
        trackingInfo: '',
        recipientName: '',
        contactNumber: '',
        quantity: 1, // Default quantity is 1
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedBlood, setSelectedBlood] = useState(null); // Store the selected blood details

    // Get the admin username from local storage
    const adminUsername = localStorage.getItem('username'); // Adjust the key as necessary

    // Function to generate tracking info
    const generateTrackingInfo = () => {
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        return `${year}${month}${day}-${hours}${minutes}${seconds}`;
    };
    

    useEffect(() => {
        if (order) {
            setFormData({
                bagID: order.bagID,
                trackingInfo: order.trackingInfo,
                recipientName: order.recipient?.name || '',
                contactNumber: order.contactNumber || '',
                quantity: order.quantity || 1, // Ensure the existing quantity is set
            });
        } else {
            // Generate tracking info when creating a new order
            setFormData((prevData) => ({
                ...prevData,
                trackingInfo: generateTrackingInfo(),
            }));
        }
    }, [order]);

    const handleSelectBlood = (blood, selectedQuantity) => {
        setSelectedBlood(blood);
        setFormData({
            ...formData,
            bagID: blood.bagID,
            trackingInfo: formData.trackingInfo, // Keep the generated tracking info
            quantity: selectedQuantity, // Set selected quantity
        });
        setModalOpen(false);
    };

    const handleQuantityChange = (e) => {
        const newQuantity = Math.max(1, Number(e.target.value)); // Ensure it's at least 1
        if (selectedBlood && newQuantity > selectedBlood.quantity) {
            alert(`Not enough stock available. Only ${selectedBlood.quantity} left.`);
        } else {
            setFormData({ ...formData, quantity: newQuantity });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Ensure all required fields are filled
        if (!formData.bagID || !formData.recipientName || !formData.contactNumber || !formData.quantity) {
            setSuccessMessage('Please fill all required fields.');
            return;
        }

        // Define dataToSubmit here
        const dataToSubmit = {
            ...formData,
            recipient: {
                name: formData.recipientName,
            },
            approvalStatus: 'Pending', // Set initial approval status
            adminUsername, // Pass the admin username here
        };

        console.log("Submitting data:", dataToSubmit); // Log the data being submitted

        try {
            let response;
            if (order) {
                // Update existing order
                response = await axios.patch(`${process.env.REACT_APP_API_URL}/orders/${order._id}`, dataToSubmit);
            } else {
                // Create new order
                response = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, dataToSubmit);
            }
            setSuccessMessage(order ? 'Order updated successfully!' : 'Order created successfully!');
            setTimeout(() => {
                setSuccessMessage('');
                onClose();
            }, 3000);
        } catch (error) {
            console.error("Error submitting order:", error.response ? error.response.data : error.message);
            setSuccessMessage("Error submitting order.");
        }
    };

    return (
        <div className="relative">
            <form onSubmit={handleSubmit} className="p-6 bg-white rounded-lg shadow-md max-w-3xl mx-auto mt-16">
                <h2 className="text-2xl font-bold mb-4">{order ? 'Update Order' : 'Create Order'}</h2>

                {successMessage && (
                    <div className="mb-4 text-green-500 text-center">{successMessage}</div>
                )}

                <div className="mb-4">
                    <label className="block mb-2">
                        Selected Blood Bag ID:
                        <input
                            type="text"
                            value={formData.bagID} // Display selected bagID here
                            disabled
                            className="border-0 rounded p-2 w-full mb-2"
                        />
                    </label>
                    <button
                        type="button"
                        onClick={() => setModalOpen(true)}
                        className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    >
                        Select Blood
                    </button>
                </div>

                <div className="mb-4">
                    <label className="block mb-2">
                        Tracking Info:
                        <input
                            type="text"
                            value={formData.trackingInfo}
                            onChange={(e) => setFormData({ ...formData, trackingInfo: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                <div className="mb-4">
                    <label className="block mb-2">
                        Recipient Name:
                        <input
                            type="text"
                            value={formData.recipientName}
                            onChange={(e) => setFormData({ ...formData, recipientName: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                <div className="mb-4">
                    <label className="block mb-2">
                        Contact Number:
                        <input
                            type="text"
                            value={formData.contactNumber}
                            onChange={(e) => setFormData({ ...formData, contactNumber: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                <div className="mb-4">
                    <label className="block mb-2">
                        Quantity:
                        <input
                            type="number"
                            value={formData.quantity}
                            onChange={handleQuantityChange} // Use new handler
                            min="1"
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                <button
                    type="submit"
                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200 w-full"
                >
                    {order ? 'Update Order' : 'Create Order'}
                </button>

                <button
                    type="button"
                    onClick={onClose}
                    className="mt-2 ml-2 bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-200 w-full"
                >
                    Cancel
                </button>
            </form>

            {modalOpen && (
                <BloodSelectionModal
                    show={modalOpen}
                    onClose={() => setModalOpen(false)}
                    bloods={bloods} // Pass available bloods to the modal
                    onSelectBlood={handleSelectBlood}
                />
            )}
        </div>
    );
};

export default OrderForm;
