import React, { useState } from 'react';
import axios from 'axios';

const Tracking = () => {
    const [trackingInfo, setTrackingInfo] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');

    // Predefined messages for different delivery statuses
    const deliveryStatusMessages = {
        'Order Received': `Hello ${result?.order?.recipient?.name || 'Customer'},\n\nWe have successfully received your order. Please note that processing times may vary, and your order will be updated accordingly. If you have any questions, feel free to contact us.\n\nBest regards, The Blood Bank Team.`,
        'Packed': `Hello ${result?.order?.recipient?.name || 'Customer'},\n\nGood news! Your order has been packed and is now ready for the next steps. Please keep an eye on your notifications for further updates. If you have any questions, please don't hesitate to reach out.\n\nBest regards, The Blood Bank Team.`,
        'Ready for Pickup': `Hello ${result?.order?.recipient?.name || 'Customer'},\n\nYour order is now ready for pickup at our facility. Please ensure to pick it up within the next 24 hours. For any assistance, feel free to contact us.\n\nBest regards, The Blood Bank Team.`,
        'Received': `Hello ${result?.order?.recipient?.name || 'Customer'},\n\nWe’re happy to inform you that your order has been successfully picked up. Thank you for choosing us! If you have any further questions, don't hesitate to contact us.\n\nBest regards, The Blood Bank Team.`,
        'Canceled': `Hello ${result?.order?.recipient?.name || 'Customer'},\n\nWe regret to inform you that your order has been canceled. If this was an error or if you have any questions, please reach out to our support team for assistance.\n\nBest regards, The Blood Bank Team.`,
    };

    // Timeline steps for the order status
    const timelineSteps = [
        { status: 'Order Received', description: 'We have received your order and it is being processed.' },
        { status: 'Packed', description: 'Your order has been packed and is awaiting the next steps.' },
        { status: 'Ready for Pickup', description: 'Your order is ready for pickup at our facility.' },
        { status: 'Received', description: 'The order has been successfully picked up by the recipient.' },
        { status: 'Canceled', description: 'The order has been canceled.' }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tracking/${trackingInfo}`);
            if (response.data) {
                setResult(response.data);
            }
        } catch (err) {
            setError('Unable to fetch the order status. Please check your input.');
            console.error("Error fetching order status:", err);
        }
    };

    return (
        <div className="container mx-auto p-4 md:p-8">
            <header className="flex items-center justify-between mb-6">
                <div className="logo">
                    <a href="/">
                        <img src="/logo.png" alt="Company Logo" className="h-12" />
                    </a>
                </div>
            </header>

            <h1 className="text-center text-3xl pt-10 font-bold text-red-600">Blood Tracking System</h1>
            <form onSubmit={handleSubmit} className="mt-6 bg-white p-6 rounded-lg shadow-lg space-y-4">
                <div>
                    <label className="block text-gray-700 text-lg">Tracking Info:</label>
                    <input
                        type="text"
                        value={trackingInfo}
                        onChange={(e) => setTrackingInfo(e.target.value)}
                        className="border border-gray-300 rounded p-3 w-full focus:ring focus:ring-red-300 transition"
                        placeholder="Enter Tracking Info"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-red-600 text-white py-3 rounded-lg hover:bg-red-700 transition"
                >
                    Track
                </button>
            </form>

            {error && (
                <div className="mt-4 text-red-500 text-lg text-center">{error}</div>
            )}

            {result && (
                <div className="mt-6 p-4 border rounded-lg shadow-md bg-gray-50">
                    <h2 className="text-xl font-semibold text-gray-800">Tracking Result</h2>
                {/* Order Information Table */}
<div className="min-w-full mt-4 bg-white border border-gray-300 p-4">
  <table className="w-full">
    <thead>
      <tr className="bg-gray-200">
        <th className="p-2 text-left border">Information</th>
        <th className="p-2 text-left border">Details</th>
      </tr>
    </thead>
    <tbody>
      {/* Recipient Information */}
      <tr>
        <td className="p-2 border">Recipient Name</td>
        <td className="p-2 border">{result.order.recipient.name}</td>
      </tr>

      {/* Total Blood Bags */}
      <tr>
        <td className="p-2 border">Total Blood Bags</td>
        <td className="p-2 border">{result.blood.length}</td>
      </tr>

      {/* Delivery Status */}
      <tr>
        <td className="p-2 border">Delivery Status</td>
        <td className="p-2 border">{result.order.deliveryStatus}</td>
      </tr>
    </tbody>
  </table>
</div>

{/* Blood Bag Information Boxes */}
<div className="mt-4">
  {result.blood.map((blood, index) => (
    <div key={index} className="bg-white border border-gray-300 p-4 mb-4">
      <h3 className="font-bold text-lg">Blood Bag {index + 1}</h3>
      <table className="w-full mt-2">
        <tbody>
          <tr>
            <td className="p-2 border">Blood Type</td>
            <td className="p-2 border">{blood.bloodType}</td>
          </tr>
          <tr>
            <td className="p-2 border">Volume (mL)</td>
            <td className="p-2 border">{blood.volume} mL</td>
          </tr>
        </tbody>
      </table>
    </div>
  ))}
</div>


                    {/* Timeline Section */}
                    <div className="mt-6 p-4">
                        <h3 className="text-xl font-semibold text-gray-800">Order Status Timeline</h3>
                        <div className="relative mt-4">
                            <div className="border-l-2 border-gray-200">
                                {timelineSteps.map((step, index) => {
                                    const isActive = step.status === result.order.deliveryStatus;
                                    const isPast = index < timelineSteps.findIndex((s) => s.status === result.order.deliveryStatus);
                                    const isFuture = index > timelineSteps.findIndex((s) => s.status === result.order.deliveryStatus);

                                    return (
                                        <div
                                            key={step.status}
                                            className={`relative pl-6 mb-8 ${isPast ? 'text-red-600' : isActive ? 'text-red-600' : 'text-gray-600'}`}
                                        >
                                            <div
                                                className={`absolute left-0 top-0 transform -translate-x-1/2 ${
                                                    isPast || isActive ? 'bg-red-600' : 'bg-gray-300'
                                                } w-4 h-4 rounded-full`}
                                            />
                                    <div className="flex items-center space-x-3">
  <div>
    <span
      className={`h-9 w-9 text-center rounded-full flex items-center justify-center ring-8 ring-white ${
        isPast || isActive ? 'bg-red-600' : 'bg-gray-300'
      }`}
    >
      <svg
        className="h-5 w-5 text-white"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
      >
        {step.status === 'Order Received' && (
          <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5m2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2z" />
        )}
        {step.status === 'Packed' && (
          <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
        )}
        {step.status === 'Ready for Pickup' && (
          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
        )}
        {step.status === 'Received' && (
          <path d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" />
        )}
        {step.status === 'Canceled' && (
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        )}
      </svg>
    </span>
  </div>
  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
    <div>
      <p className="text-sm text-gray-500">{step.description}</p>
    </div>
    <div className="whitespace-nowrap text-right text-sm text-gray-500">
      <time dateTime={new Date().toISOString()}>{step.status}</time>
    </div>
  </div>
</div>

                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {/* Delivery status-specific message */}
                    <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
                        <h3 className="text-lg font-semibold text-gray-800">Order Delivery Status Message</h3>
                        <p className="text-gray-700 mt-2">{deliveryStatusMessages[result.order.deliveryStatus]}</p>
                    </div>
                </div>
            )}

            {/* Footer Section */}
            <footer className="mt-10 p-4 bg-gray-200 rounded-lg text-center">
                <h3 className="font-bold text-lg">Philippine Red Cross National Headquarters</h3>
                <p>Address: 37 EDSA, corner Boni Ave, Mandaluyong, 1550</p>
                <p>Hours: Open 24 hours</p>
                <p>Phone: (02) 8790 2300</p>
            </footer>
        </div>
    );
};

export default Tracking;
