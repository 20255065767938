// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Tracking from './components/Tracking';
import Admin from './components/Admin';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Bloods from './components/Bloods';
import AddBlood from './components/AddBlood';
import Orders from './components/Orders';
import ManageAdmins from './components/ManageAdmins'; 
import SuperAdminOrders from './components/SuperAdminOrders';
import AuditLogs from './components/AuditLogs'; // Import the AuditLogs component
import Navbar from './components/Navbar'; 
import Analytics from './components/Analytics'; 

const App = () => {
    const isAuthenticated = () => {
        return !!localStorage.getItem('token'); 
    };

    const isSuperAdmin = () => {
        const token = localStorage.getItem('token');
        if (!token) return false;

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.role === 'superadmin';
        } catch (error) {
            console.error("Failed to decode token:", error);
            return false;
        }
    };

    const ProtectedRoute = ({ children }) => {
        return isAuthenticated() ? children : <Navigate to="/admin" />;
    };

    const SuperAdminRoute = ({ children }) => {
        return isSuperAdmin() ? children : <Navigate to="/dashboard" />;
    };

    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/tracking" replace />} />
                <Route path="/tracking" element={<Tracking />} />
                <Route path="/admin" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Login />} />
                <Route path="/analytics" element={<><Navbar /><ProtectedRoute><Analytics /></ProtectedRoute></>} />

                <Route path="/dashboard" element={<><Navbar /><ProtectedRoute><Dashboard /></ProtectedRoute></>} />
                <Route path="/bloods" element={<><Navbar /><ProtectedRoute><Bloods /></ProtectedRoute></>} />
                <Route path="/add-blood" element={<><Navbar /><ProtectedRoute><AddBlood /></ProtectedRoute></>} />
                <Route path="/orders" element={<><Navbar /><ProtectedRoute><Orders /></ProtectedRoute></>} />
                <Route path="/manage-admins" element={<><Navbar /><ProtectedRoute><ManageAdmins /></ProtectedRoute></>} />
                <Route path="/super-admin-orders" element={<><Navbar /><SuperAdminRoute><SuperAdminOrders /></SuperAdminRoute></>} />
                <Route path="/audit-logs" element={<><Navbar /><ProtectedRoute><AuditLogs /></ProtectedRoute></>} /> {/* New route for Audit Logs */}
            </Routes>
        </div>
    );
};

const Main = () => (
    <Router>
        <App />
    </Router>
);

export default Main;
