import React, { useState } from 'react';
import axios from 'axios';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/login`, { username, password });
            const { token, role } = res.data; // Assuming the response includes the role

            // Store token and user info in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('username', username); // Store username
            localStorage.setItem('role', res.data.role); // Store the role in local storage

            // Redirect to dashboard
            window.location.href = '/dashboard';
        } catch (err) {
            setError('Invalid credentials');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-10 rounded-lg shadow-lg w-96">
                <div className="flex justify-center mb-8">
                    <a href="/">
                        <img src="/logo.png" alt="Company Logo" className="h-12" />
                    </a>
                </div>
                <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Admin Login</h2>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        placeholder="Username"
                        className="border border-gray-300 p-4 mb-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        className="border border-gray-300 p-4 mb-6 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button
                        type="submit"
                        className="bg-red-500 text-white p-4 w-full rounded-lg hover:bg-red-600 transition duration-200"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
