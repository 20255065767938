import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SuperAdminOrders = () => {
    const [orders, setOrders] = useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [selectedBlood, setSelectedBlood] = useState(null);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders`);
            setOrders(response.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const handleApprovalChange = async (orderId, approvalStatus) => {
        try {
            const orderToUpdate = orders.find(order => order._id === orderId);
            const { bagID, quantity } = orderToUpdate;
    
            // Get the admin username from local storage
            const adminUsername = localStorage.getItem('username');
    
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/orders/approve/${orderId}`,
                { approvalStatus, adminUsername }, // Include username here
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
    
            if (approvalStatus === 'Approved') {
                await axios.patch(
                    `${process.env.REACT_APP_API_URL}/bloods/${bagID}`,
                    { quantity: -quantity },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                setConfirmationMessage('Order approved and blood quantity decreased successfully!');
            } else {
                setConfirmationMessage('Order disapproved successfully!');
            }
    
            setOrders(prevOrders => prevOrders.filter(order => order._id !== orderId));
            setTimeout(() => setConfirmationMessage(''), 3000);
        } catch (error) {
            console.error("Error updating order approval:", error.response ? error.response.data : error.message);
            setConfirmationMessage('Error updating order approval. Please try again.');
            setTimeout(() => setConfirmationMessage(''), 3000);
        }
    };
    

    const viewBloodInfo = async (bagID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bloods/${bagID}`);
            setSelectedBlood(response.data);
        } catch (error) {
            console.error("Error fetching blood info:", error);
        }
    };

    const pendingOrders = orders.filter(order => order.approvalStatus === 'Pending');
    const approvedOrders = orders.filter(order => order.approvalStatus === 'Approved');
    const disapprovedOrders = orders.filter(order => order.approvalStatus === 'Not Approved');

    return (
        <div className="container mx-auto p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-center text-red-600 mt-5">Super Admin Orders Management</h1>

            {confirmationMessage && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center z-50">
                    {confirmationMessage}
                </div>
            )}

            <h2 className="text-3xl mt-8 text-gray-800">Submitted - Pending Orders</h2>
            <OrdersTable 
                orders={pendingOrders} 
                onApprovalChange={handleApprovalChange} 
                onViewBlood={viewBloodInfo}
            />

            <h2 className="text-3xl mt-8 text-gray-800">Approved Orders</h2>
            <OrdersTable 
                orders={approvedOrders} 
                onApprovalChange={handleApprovalChange} 
                onViewBlood={viewBloodInfo}
            />

            <h2 className="text-3xl mt-8 text-gray-800">Disapproved Orders</h2>
            <OrdersTable 
                orders={disapprovedOrders} 
                onApprovalChange={handleApprovalChange} 
                onViewBlood={viewBloodInfo}
            />

            {selectedBlood && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50">
                    <div className="bg-white w-full max-w-4xl p-8 rounded-lg shadow-lg transform transition-all duration-300">
                        <h3 className="text-2xl font-bold text-center mb-6">Blood Information</h3>
                        <div className="flex space-x-8">
                            <div className="flex-1 space-y-4">
                                <p className="flex justify-between"><strong>Bag ID:</strong> <span>{selectedBlood.bagID}</span></p>
                                <p className="flex justify-between"><strong>Blood Type:</strong> <span>{selectedBlood.bloodType}</span></p>
                                <p className="flex justify-between"><strong>Volume:</strong> <span>{selectedBlood.volume} ml</span></p>
                                <p className="flex justify-between"><strong>Status:</strong> <span>{selectedBlood.status}</span></p>
                                <p className="flex justify-between"><strong>Quantity:</strong> <span>{selectedBlood.quantity}</span></p>
                                <p className="flex justify-between"><strong>Collection Date:</strong> <span>{new Date(selectedBlood.collectionDate).toLocaleDateString()}</span></p>
                            </div>

                            <div className="flex-1 space-y-4">
                                <h4 className="text-lg font-semibold">Components:</h4>
                                {selectedBlood.components && selectedBlood.components.length > 0 ? (
                                    selectedBlood.components.map((component, index) => (
                                        <div key={index} className="border-b pb-4">
                                            <p><strong>Name:</strong> {component.name}</p>
                                            <p><strong>Volume:</strong> {component.volume} ml</p>
                                            <p><strong>Expiration Date:</strong> {new Date(component.expirationDate).toLocaleDateString()}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-600">No components available.</p>
                                )}
                            </div>
                        </div>

                        <button
                            onClick={() => setSelectedBlood(null)}
                            className="mt-6 w-full bg-red-600 text-white py-2 rounded hover:bg-red-500 transition duration-200"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const OrdersTable = ({ orders, onApprovalChange, onViewBlood }) => (
    <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
                <tr className="bg-red-600 text-white">
                    <th className="py-4 px-6 border-b text-left">Order ID</th>
                    <th className="py-4 px-6 border-b text-left">Recipient Name</th>
                    <th className="py-4 px-6 border-b text-left">Quantity</th>

                    <th className="py-4 px-6 border-b text-left">Order Status</th>
                    <th className="py-4 px-6 border-b text-left">Approval Status</th>
                    <th className="py-4 px-6 border-b text-left">Actions</th>
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr key={order._id} className="hover:bg-gray-100">
                        <td className="py-4 px-6 border-b text-left">{order.orderID}</td>
                        <td className="py-4 px-6 border-b text-left">{order.recipient?.name || 'N/A'}</td>
                        <td className="py-4 px-6 border-b text-left">{order.quantity}</td> {/* Quantity column */}

                        <td className="py-4 px-6 border-b text-left">{order.deliveryStatus}</td>
                        <td className="py-4 px-6 border-b text-left">{order.approvalStatus}</td>
                        <td className="py-4 px-6 border-b text-left">
                            {order.approvalStatus === 'Pending' && (
                                <>
                                    <button
                                        onClick={() => onApprovalChange(order._id, 'Approved')}
                                        className="bg-green-600 text-white py-1 px-3 rounded transition duration-200 hover:bg-green-500"
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => onApprovalChange(order._id, 'Not Approved')}
                                        className="bg-red-600 text-white py-1 px-3 rounded transition duration-200 hover:bg-red-500 ml-4"
                                    >
                                        Disapprove
                                    </button>
                                </>
                            )}
                            <button
                                onClick={() => onViewBlood(order.bagID)}
                                className="bg-blue-600 text-white py-1 px-3 rounded transition duration-200 hover:bg-blue-500 ml-4"
                            >
                                View Blood
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default SuperAdminOrders;
