import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OrderForm from './OrderForm';
import ReactPaginate from 'react-paginate'; // Import react-paginate

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [bloods, setBloods] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOrderFormOpen, setIsOrderFormOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('pending');
    const [selectedBlood, setSelectedBlood] = useState(null);
    const [isBloodModalOpen, setIsBloodModalOpen] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const ordersPerPage = 10; // Number of orders per page

    const adminUsername = localStorage.getItem('username'); // Adjust the key as necessary

    useEffect(() => {
        const role = localStorage.getItem('role'); // Get the role from localStorage
        setUserRole(role);
        fetchOrders();
        fetchBloods();

        // Set active tab for medical practitioners to 'approved' by default
        if (role === 'medical_practitioner') {
            setActiveTab('approved');
        }
    }, []);

    useEffect(() => {
        setFilteredOrders(
            orders.filter(order =>
                // Handle bagID being an array
                (Array.isArray(order.bagID)
                    ? order.bagID.some(bag => typeof bag === 'string' && bag.toLowerCase().includes(searchTerm.toLowerCase()))
                    : (typeof order.bagID === 'string' && order.bagID.toLowerCase().includes(searchTerm.toLowerCase()))
                ) ||
                (order.recipient?.name && order.recipient.name.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        );
    }, [searchTerm, orders]);
    
    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders`);
            setOrders(response.data);
            setFilteredOrders(response.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const fetchBloods = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bloods`);
            setBloods(response.data);
        } catch (error) {
            console.error("Error fetching bloods:", error);
        }
    };

    const openOrderForm = (order) => {
        if (userRole === 'superadmin' || userRole === 'admin') {
            setSelectedOrder(order);
            setIsOrderFormOpen(true);
        }
    };

    const closeOrderForm = () => {
        setIsOrderFormOpen(false);
        setSelectedOrder(null);
        fetchOrders();
    };
    const sendSMS = async (order) => {
        // Get the current date and time with the day of the week
        const currentDate = new Date();
        const currentTime = currentDate.toLocaleTimeString();
        const currentDay = currentDate.toLocaleDateString('en-US', {
            weekday: 'long', // day of the week (e.g., Monday)
            year: 'numeric', // full year (e.g., 2024)
            month: 'long',   // full month name (e.g., November)
            day: 'numeric'   // day of the month (e.g., 19)
        });
    
        // Tracking info (the part you wanted to send in the message)
        const trackingInfo = order.trackingInfo;
    
        const statusMessages = {
            'Order Received': `Hello ${order.recipient?.name || 'Customer'},\n\nWe have successfully received your order. Tracking number: ${trackingInfo} | You may track it here at : bloodwatchph(.)com. Please note that processing times may vary, and your order will be updated accordingly. If you have any questions, feel free to contact us. \n\nThis message was sent on ${currentDay} at ${currentTime}.`,
            'Packed': `Hello ${order.recipient?.name || 'Customer'},\n\nGood news! Your order is now packed and ready for the next steps. Tracking number: ${trackingInfo} | You may track it here at : bloodwatchph(.)com. Keep an eye on your notifications for further updates. \n\nThis message was sent on ${currentDay} at ${currentTime}.`,
            'Ready for Pickup': `Hello ${order.recipient?.name || 'Customer'},\n\nYour order is now ready for pickup at our facility. Tracking number: ${trackingInfo} | You may track it here at : bloodwatchph(.)com. Please ensure to pick it up within the next 24 hours. \n\nThis message was sent on ${currentDay} at ${currentTime}.`,
            'Received': `Hello ${order.recipient?.name || 'Customer'},\n\nWe’re happy to inform you that your order has been successfully picked up. Thank you for choosing us! Tracking number: ${trackingInfo}. \n\nThis message was sent on ${currentDay} at ${currentTime}.`,
            'Canceled': `Hello ${order.recipient?.name || 'Customer'},\n\nWe regret to inform you that your order has been canceled. If this was an error or if you have any questions, please reach out to our support team. Tracking number: ${trackingInfo}. \n\nThis message was sent on ${currentDay} at ${currentTime}.`
        };
    
        const message = statusMessages[order.deliveryStatus] || `Hello ${order.recipient?.name || 'Customer'},\n\nYour order status has been updated. Tracking number: ${trackingInfo}. This message was sent on ${currentDay} at ${currentTime}.`;
    
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/send-sms`, {
                number: order.contactNumber,
                message: message,
                senderName: 'Thesis',
            });
            setConfirmationMessage('SMS sent successfully!');
            setTimeout(() => setConfirmationMessage(''), 3000);
        } catch (error) {
            console.error("Error sending SMS:", error);
            setConfirmationMessage('Failed to send SMS. Please try again.');
            setTimeout(() => setConfirmationMessage(''), 3000);
        }
    };
    
    
    

    const handleDeleteOrder = async (orderId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this order?");
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/orders/${orderId}`);
                setConfirmationMessage('Order deleted successfully!');
                setTimeout(() => setConfirmationMessage(''), 3000);
                fetchOrders();
            } catch (error) {
                console.error("Error deleting order:", error);
            }
        }
    };

    const handleStatusChange = async (orderId, updatedStatus) => {
        try {
            const dataToSubmit = {
                deliveryStatus: updatedStatus,
                adminUsername,// Replace this with your actual variable holding the admin username
            };
    
            await axios.patch(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, dataToSubmit);
            setConfirmationMessage(`Order status updated to "${updatedStatus}" successfully!`);
            setTimeout(() => setConfirmationMessage(''), 3000);
            fetchOrders();
            const updatedOrder = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`);
            await sendSMS(updatedOrder.data);
        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };

    const pendingOrders = filteredOrders.filter(order => 
        order.approvalStatus !== "Approved" && 
        order.deliveryStatus !== "Canceled" &&
        order.approvalStatus !== "Not Approved" // Exclude disapproved orders
    );
        const approvedOrders = filteredOrders.filter(order => order.approvalStatus === "Approved" && order.deliveryStatus !== "Canceled" && order.deliveryStatus !== "Received");
    const receivedOrders = filteredOrders.filter(order => order.deliveryStatus === "Received");
    const disapprovedOrders = filteredOrders.filter(order => order.approvalStatus == "Not Approved");
    const canceledOrders = filteredOrders.filter(order => order.deliveryStatus === "Canceled");

    const viewBlood = async (bagID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bloods/${bagID}`);
            setSelectedBlood(response.data);
            setIsBloodModalOpen(true);
        } catch (error) {
            console.error("Error fetching blood details:", error);
        }
    };

    // Pagination handler
    const handlePageClick = (selectedItem) => {
        setCurrentPage(selectedItem.selected);
    };

    // Paginate filtered orders
    const getPaginatedOrders = (orders) => {
        const offset = currentPage * ordersPerPage;
        return orders.slice(offset, offset + ordersPerPage);
    };

    return (
        <div className="mx-auto p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-center text-red-600 mt-5">Orders Management</h1>
            <div className="text-center mt-5">
                {userRole !== 'medical_practitioner' && (
                    <button onClick={() => openOrderForm(null)} className="bg-red-600 text-white py-3 px-6 rounded-full shadow-lg hover:bg-red-500 transition">
                        Add Order
                    </button>
                )}
            </div>

            {confirmationMessage && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center">
                    {confirmationMessage}
                </div>
            )}
<div className="flex justify-around mt-8">
    {userRole !== 'medical_practitioner' && (
        <>
            <button onClick={() => setActiveTab('pending')} className={`px-4 py-2 ${activeTab === 'pending' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Pending Approval</button>
            <button onClick={() => setActiveTab('disapproved')} className={`px-4 py-2 ${activeTab === 'disapproved' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Disapproved Orders</button>
        </>
    )}
    <button onClick={() => setActiveTab('approved')} className={`px-4 py-2 ${activeTab === 'approved' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Approved Orders</button>
    {userRole !== 'medical_practitioner' && (
        <>
            <button onClick={() => setActiveTab('received')} className={`px-4 py-2 ${activeTab === 'received' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Past Orders (Received)</button>
            <button onClick={() => setActiveTab('canceled')} className={`px-4 py-2 ${activeTab === 'canceled' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Canceled Orders</button>
        </>
    )}
</div>


            {activeTab === 'pending' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Pending Orders</h2>
                    <OrdersTable 
                        orders={getPaginatedOrders(pendingOrders)}
                        onStatusChange={handleStatusChange}
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                        showStatus={false}
                        onViewBlood={viewBlood}
                    />
                </>
            )}

            {activeTab === 'approved' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Approved Orders</h2>
                    <OrdersTable 
                        orders={getPaginatedOrders(approvedOrders)} 
                        onStatusChange={handleStatusChange}
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                        showStatus={true} 
                        onViewBlood={viewBlood} 
                        onSendSMS={sendSMS} // Added this line
                    />
                </>
            )}

            {activeTab === 'received' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Received Orders</h2>
                    <OrdersTable 
                        orders={getPaginatedOrders(receivedOrders)} 
                        onEdit={null}
                        onDelete={null}
                        showStatus={false} 
                        isEditable={false} 
                        onViewBlood={viewBlood} 
                    />
                </>
            )}

            {activeTab === 'disapproved' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Disapproved Orders</h2>
                    <OrdersTable 
                        orders={getPaginatedOrders(disapprovedOrders)} 
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                        showStatus={false} 
                        onViewBlood={viewBlood} 
                    />
                </>
            )}


{activeTab === 'canceled' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Cancelled Orders</h2>
                    <OrdersTable 
                        orders={getPaginatedOrders(canceledOrders)} 
                        onStatusChange={handleStatusChange}
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                    />
                </>
            )}

            {isOrderFormOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <OrderForm 
                            order={selectedOrder} 
                            bloods={bloods} 
                            onClose={closeOrderForm} 
                        />
                    </div>
                </div>
            )}
            {isBloodModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <h2 className="text-xl font-bold">Blood Details</h2>
                        <p><strong>Bag ID:</strong> {selectedBlood.bagID}</p>
                        <p><strong>Type:</strong> {selectedBlood.bloodType || 'N/A'}</p>
                        <p><strong>Volume:</strong> {selectedBlood.volume || 'N/A'} mL</p>
                        <p><strong>Collection Date:</strong> {new Date(selectedBlood.collectionDate).toLocaleDateString() || 'N/A'}</p>
                        <p><strong>Status:</strong> {selectedBlood.status || 'N/A'}</p>

                        {selectedBlood.components && selectedBlood.components.length > 0 && (
                            <div className="mt-4">
                                <strong>Components:</strong>
                                <ul className="list-disc pl-5">
                                    {selectedBlood.components.map((component, index) => (
                                        <li key={index}>
                                            {component.name} - {component.included ? `${component.volume || 'N/A'} mL` : 'N/A units'} 
                                            {component.included && component.expirationDate && (
                                                <span> (Expires: {new Date(component.expirationDate).toLocaleDateString()})</span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <button onClick={() => setIsBloodModalOpen(false)} className="mt-4 bg-red-600 text-white py-2 px-4 rounded">Close</button>
                    </div>
                </div>
            )}

            {/* Pagination Controls */}
            <div className="mt-8">
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(filteredOrders.length / ordersPerPage)}
                    onPageChange={handlePageClick}
                    containerClassName={'flex justify-center space-x-2'}
                    activeClassName={'bg-red-600 text-white py-1 px-3 rounded'}
                    pageClassName={'py-1 px-3'}
                    disabledClassName={'text-gray-400'}
                />
            </div>
        </div>
    );
};

const OrdersTable = ({ orders, onStatusChange, onEdit, onDelete, showStatus, onViewBlood, onSendSMS }) => (
    <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
                <tr className="bg-red-600 text-white">
                    <th className="py-4 px-6 border-b text-left">Order ID</th>
                    <th className="py-4 px-6 border-b text-left">Blood Bag ID</th>
                    <th className="py-4 px-6 border-b text-left">Tracking Info</th>
                    <th className="py-4 px-6 border-b text-left">Recipient Name</th>
                    <th className="py-4 px-6 border-b text-left">Contact Number</th>
                    {showStatus && <th className="py-4 px-6 border-b text-left">Order Status</th>}
                    {showStatus && <th className="py-4 px-6 border-b text-left">Actions</th>}
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr key={order._id} className="hover:bg-gray-100">
                        <td className="py-4 px-6 border-b text-left">{order.orderID}</td>
                        <td className="py-4 px-6 border-b text-left">
                            {order.bagID && Array.isArray(order.bagID) ? (
                                order.bagID.map((bag, index) => (
                                    <div key={index}>
                                        {/* Render meaningful information from bag object */}
                                        <strong>Blood Bag {index + 1}:</strong>
                                        <p>Bag ID: {bag.bagID || bag.$oid || 'N/A'}</p>
                                        <p>Blood Type: {bag.bloodType || 'N/A'}</p>
                                    </div>
                                ))
                            ) : (
                                'No Blood Bags'
                            )}
                        </td>
                        <td className="py-4 px-6 border-b text-left">{order.trackingInfo}</td>
                        <td className="py-4 px-6 border-b text-left">{order.recipient?.name || 'N/A'}</td>
                        <td className="py-4 px-6 border-b text-left">{order.contactNumber || 'N/A'}</td>
                        {showStatus && (
                            <>
                                <td className="py-4 px-6 border-b text-left">
                                    <select
                                        value={order.deliveryStatus}
                                        onChange={(e) => onStatusChange(order._id, e.target.value)}
                                        className="border rounded-lg p-1"
                                    >
                                        <option value="Order Received">Order Received</option>
                                        <option value="Packed">Packed</option>
                                        <option value="Ready for Pickup">Ready for Pickup</option>
                                        <option value="Received">Received</option>
                                        <option value="Canceled">Canceled</option>
                                    </select>
                                </td>
                                <td className="py-4 px-6 border-b text-left">
                                    {onEdit && (
                                        <button 
                                            onClick={() => onEdit(order)} 
                                            className="text-white bg-blue-600 hover:bg-blue-700 py-1 px-3 rounded transition duration-200"
                                        >
                                            Edit
                                        </button>
                                    )}
                                    {onDelete && (
                                        <button 
                                            onClick={() => onDelete(order._id)} 
                                            className="text-white bg-red-600 hover:bg-red-700 py-1 px-3 rounded transition duration-200 ml-4"
                                        >
                                            Delete
                                        </button>
                                    )}
                              
                                    {onSendSMS && (
                                        <button 
                                            onClick={() => onSendSMS(order)} 
                                            className="text-white bg-yellow-600 hover:bg-yellow-700 py-1 px-3 rounded transition duration-200 ml-4"
                                        >
                                            Send SMS
                                        </button>
                                    )}
                                </td>
                            </>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default Orders;
