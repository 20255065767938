import React from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const Navbar = () => {
    const [cookies, removeCookie] = useCookies();

    const handleLogout = () => {
        localStorage.clear();
        Object.keys(cookies).forEach(cookieName => {
            removeCookie(cookieName);
        });
        window.location.href = '/admin';
    };

    const getUserRole = () => {
        const token = localStorage.getItem('token');
        if (!token) return null;

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.role;
        } catch (error) {
            console.error("Failed to decode token:", error);
            return null;
        }
    };

    const userRole = getUserRole();
    const username = localStorage.getItem('username'); // Retrieve username from localStorage

    return (
        <nav className="bg-red-600 p-4 text-white">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/dashboard" className="flex items-center">
                    <img src="/logo192.png" alt="Company Logo" className="h-10 mr-2" />
                    <span className="text-lg font-bold">Dashboard</span>
                </Link>

                <div className="flex items-center">
                    <Link to="/bloods" className="mr-5 hover:text-gray-200">Bloods</Link>
                    <Link to="/orders" className="mr-5 hover:text-gray-200">Orders</Link>
                    {userRole === 'superadmin' && (
                        <>
                            <Link to="/manage-admins" className="mr-5 hover:text-gray-200">Manage Admins</Link>
                            <Link to="/super-admin-orders" className="mr-5 hover:text-gray-200">Approvals</Link>
                            <Link to="/audit-logs" className="mr-5 hover:text-gray-200">Logs</Link>

                        </>
                    )}
                                        {username && <span className="mr-5">Hello, {username}!</span>} {/* Display username */}

                    <button 
                        onClick={handleLogout} 
                        className="bg-white text-red-500 px-4 py-2 rounded hover:bg-gray-200 transition"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
