import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BloodForm = ({ bloodId, onClose }) => {
    const [formData, setFormData] = useState({
        bagID: '',
        bloodType: '',
        wholeBloodVolume: '',
        quantity: 1,
        collectionDate: '',
        status: 'available',
        components: [],
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isComponentsVisible, setIsComponentsVisible] = useState(true);

    const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

    useEffect(() => {
        const fetchData = async () => {
            if (bloodId) {
                setLoading(true);
                console.log(`Fetching blood details for ID: ${bloodId}`);
                try {
                    const bloodRes = await axios.get(`${process.env.REACT_APP_API_URL}/bloods/${bloodId}`);
                    const { bagID, bloodType, volume, quantity, collectionDate, status, components } = bloodRes.data;

                    setFormData({
                        bagID,
                        bloodType,
                        wholeBloodVolume: volume,
                        quantity,
                        collectionDate: collectionDate ? collectionDate.split('T')[0] : '',
                        status,
                        components: components.map(component => ({
                            ...component,
                            expirationDate: component.expirationDate ? component.expirationDate.split('T')[0] : ''
                        })),
                    });
                    console.log('Fetched blood details:', bloodRes.data);
                } catch (error) {
                    console.error("Error fetching blood details:", error);
                    setErrorMessage('Failed to fetch blood details. Please try again later.');
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [bloodId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleComponentChange = (index, e) => {
        const { name, value } = e.target;
        const updatedComponents = [...formData.components];
        updatedComponents[index][name] = value;
        setFormData((prevData) => ({
            ...prevData,
            components: updatedComponents,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting form with data:', formData);
        
        const adminUsername = localStorage.getItem('username');
        if (!adminUsername) {
            setErrorMessage('Admin username is not available. Please log in.');
            return;
        }
        
        if (!formData.bloodType || !formData.wholeBloodVolume || !formData.collectionDate) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }
        
        setErrorMessage('');
        
        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/bloods/${formData.bagID}`, { ...formData, adminUsername });
            console.log('Update response:', response.data);
            setSuccessMessage('Blood record updated successfully!');
            setTimeout(() => {
                setSuccessMessage('');
                onClose();
            }, 2000);
        } catch (error) {
            console.error("Error updating blood record:", error);
            setErrorMessage('Failed to update blood record. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={handleOutsideClick}>
            <div className="bg-white rounded-lg p-6 max-w-full w-full shadow-lg flex flex-col md:flex-row relative">
                <div className="flex-1 pr-4 mb-4 md:mb-0">
                    {successMessage && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
                            {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                            {errorMessage}
                        </div>
                    )}
                    <h2 className="text-2xl font-bold mb-4 text-center">Blood Details</h2>
                    
                    {loading ? (
                        <div className="text-center">Loading...</div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-6">
                                <div className="bg-gray-100 p-4 rounded-lg shadow">
                                    <h3 className="text-lg font-semibold mb-2">Blood Information</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-gray-700">Bag ID:</label>
                                            <input type="text" name="bagID" value={formData.bagID} className="border rounded p-2 w-full bg-gray-200" readOnly />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Blood Type:</label>
                                            <select name="bloodType" value={formData.bloodType} onChange={handleChange} className="border rounded p-2 w-full" required>
                                                <option value="">Select Blood Type</option>
                                                {bloodTypes.map((type) => (
                                                    <option key={type} value={type}>{type}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Whole Blood Volume (ml):</label>
                                            <input type="number" name="wholeBloodVolume" value={formData.wholeBloodVolume} onChange={handleChange} className="border rounded p-2 w-full" required />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Quantity:</label>
                                            <input type="number" name="quantity" value={formData.quantity} onChange={handleChange} min="1" className="border rounded p-2 w-full" required />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Collection Date:</label>
                                            <input
                                                type="date"
                                                name="collectionDate"
                                                value={formData.collectionDate}
                                                onChange={handleChange}
                                                className="border rounded p-2 w-full"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Status:</label>
                                            <select name="status" value={formData.status} onChange={handleChange} className="border rounded p-2 w-full">
                                                <option value="available">Available</option>
                                                <option value="reserved">Reserved</option>
                                                <option value="expired">Expired</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center mt-6">
                                <button type="submit" className="bg-red-500 text-white py-2 px-6 rounded-lg shadow hover:bg-red-600 transition duration-200">Update Blood Record</button>
                            </div>
                        </form>
                    )}
                </div>

                <div className="flex-1 pl-4">
                    <div className="bg-gray-100 p-4 rounded-lg shadow h-full">
                        <h3 className="text-lg font-semibold mb-2 flex items-center justify-between">
                            Components
                            <button
                                onClick={() => setIsComponentsVisible(!isComponentsVisible)}
                                className="text-sm text-blue-500 hover:underline"
                            >
                                {isComponentsVisible ? 'Hide' : 'Show'}
                            </button>
                        </h3>
                        {isComponentsVisible && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {formData.components.map((component, index) => (
                                    <div key={component._id?.$oid} className="bg-white rounded-lg p-4 shadow">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-gray-700">Component Name:</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={component.name}
                                                    className="border rounded p-2 w-full bg-gray-200"
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-gray-700">Included:</label>
                                                <select
                                                    name="included"
                                                    value={component.included ? 'true' : 'false'}
                                                    onChange={(e) => handleComponentChange(index, e)}
                                                    className="border rounded p-2 w-full"
                                                >
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className="block text-gray-700">Volume (ml):</label>
                                                <input
                                                    type="number"
                                                    name="volume"
                                                    value={component.volume}
                                                    onChange={(e) => handleComponentChange(index, e)}
                                                    className="border rounded p-2 w-full"
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-gray-700">Expiration Date:</label>
                                                <input
                                                    type="date"
                                                    name="expirationDate"
                                                    value={component.expirationDate}
                                                    onChange={(e) => handleComponentChange(index, e)}
                                                    className="border rounded p-2 w-full"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <button 
                    className="absolute top-4 right-4 text-2xl text-red-600 hover:text-red-800 transition duration-200" 
                    onClick={onClose}
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default BloodForm;
