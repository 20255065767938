import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ManageAdmins = () => {
    const [admins, setAdmins] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    useEffect(() => {
        fetchAdmins();
    }, []);

    const fetchAdmins = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admins`);
            setAdmins(response.data);
        } catch (error) {
            console.error("Error fetching admins:", error);
        }
    };

    const handleAddAdmin = async () => {
        if (!username || !password) return;
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/admins`, { username, password });
            setUsername('');
            setPassword('');
            setConfirmationMessage('Admin added successfully!');
            setTimeout(() => setConfirmationMessage(''), 3000);
            fetchAdmins(); // Refresh admin list
        } catch (error) {
            console.error("Error adding admin:", error);
        }
    };

    const handleDeleteAdmin = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this admin?");
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/admins/${id}`);
                setConfirmationMessage('Admin deleted successfully!');
                setTimeout(() => setConfirmationMessage(''), 3000);
                fetchAdmins(); // Refresh admin list
            } catch (error) {
                console.error("Error deleting admin:", error);
            }
        }
    };

    return (
        <div className="container mx-auto p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-center text-red-600 mt-5">Manage Admins</h1>

            {confirmationMessage && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center">
                    {confirmationMessage}
                </div>
            )}

            <div className="mt-5 mb-10 flex justify-center">
                <input
                    type="text"
                    placeholder="Username"
                    className="border rounded p-2 mr-2 shadow-md"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="border rounded p-2 mr-2 shadow-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    onClick={handleAddAdmin}
                    className="bg-red-600 text-white py-3 px-6 rounded-full shadow-lg hover:bg-red-500 transition"
                >
                    Add Admin
                </button>
            </div>

            <h2 className="text-3xl mt-8 text-gray-800">Admins List</h2>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                    <thead>
                        <tr className="bg-red-600 text-white">
                            <th className="py-4 px-6 border-b text-left">Username</th>
                            <th className="py-4 px-6 border-b text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map(admin => (
                            <tr key={admin._id} className="hover:bg-gray-100">
                                <td className="py-4 px-6 border-b text-left">{admin.username}</td>
                                <td className="py-4 px-6 border-b text-left">
                                    <button
                                        onClick={() => handleDeleteAdmin(admin._id)}
                                        className="bg-red-600 text-white py-1 px-2 rounded hover:bg-red-700 transition duration-200"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageAdmins;
