import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import Navbar from './Navbar';

// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalBlood: 0,
        totalOrders: 0,
        bloodTypeCounts: {},
        statusCounts: {},
        nearExpirationBloods: [],
        ordersStats: { weekly: [] },
        bloodAddedStats: { monthly: [] }
    });

    const [nearExpiryPage, setNearExpiryPage] = useState(1);
    const [expiredPage, setExpiredPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/stats`);
                const ordersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/orders-per-week`);
                const bloodAddedResponse = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/blood-added-per-month`);
                
                setStats(prevStats => ({
                    ...prevStats,
                    totalBlood: response.data.totalBlood,
                    totalOrders: response.data.totalOrders,
                    bloodTypeCounts: response.data.bloodTypeCounts,
                    statusCounts: response.data.statusCounts,
                    ordersStats: { weekly: ordersResponse.data },
                    bloodAddedStats: { monthly: bloodAddedResponse.data }
                }));
                
                const expirationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/near-expiration`);
                setStats(prevStats => ({
                    ...prevStats,
                    nearExpirationBloods: expirationResponse.data
                }));
            } catch (error) {
                console.error("Error fetching statistics:", error);
            }
        };

        fetchStats();
    }, []);

    const ordersChartData = {
        labels: stats.ordersStats.weekly.map(order => `Week ${order._id}`),
        datasets: [
            {
                label: 'Orders per Week',
                data: stats.ordersStats.weekly.map(order => order.count),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const bloodAddedChartData = {
        labels: stats.bloodAddedStats.monthly.map(month => `Month ${month._id}`),
        datasets: [
            {
                label: 'Blood Added per Month',
                data: stats.bloodAddedStats.monthly.map(month => month.count),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };

    const getMaxValue = (data) => {
        const maxValue = Math.max(...data.map(item => item.count));
        return Math.ceil(maxValue / 5) * 5; // Round up to the nearest 5
    };

    const ordersMaxValue = getMaxValue(stats.ordersStats.weekly);
    const bloodAddedMaxValue = getMaxValue(stats.bloodAddedStats.monthly);

    const chartOptions = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                max: Math.max(ordersMaxValue, bloodAddedMaxValue) || 50, // Set max to the highest of both datasets or default to 50
                ticks: {
                    stepSize: 5, // Set step size to 5 for better visualization
                    callback: (value) => {
                        return Number.isInteger(value) ? value : ''; // Show only whole numbers
                    }
                }
            }
        }
    };

    const isExpired = (expirationDate) => {
        return new Date(expirationDate) < new Date(); // Expired if the date is in the past
    };

    const sortByExpiration = (bloodRecords) => {
        return bloodRecords.sort((a, b) => {
            const aNearestExpiration = Math.min(...a.components.map(comp => new Date(comp.expirationDate)));
            const bNearestExpiration = Math.min(...b.components.map(comp => new Date(comp.expirationDate)));
            return aNearestExpiration - bNearestExpiration;
        });
    };

    const sortedNearExpirationBloods = sortByExpiration(stats.nearExpirationBloods);

    const separateComponents = (bloodRecords) => {
        const expired = [];
        const nearExpiry = [];
        bloodRecords.forEach(blood => {
            blood.components.forEach(component => {
                const expirationDate = new Date(component.expirationDate);
                if (expirationDate < new Date()) {
                    expired.push({ ...component, bagID: blood.bagID, bloodType: blood.bloodType });
                } else {
                    nearExpiry.push({ ...component, bagID: blood.bagID, bloodType: blood.bloodType });
                }
            });
        });
        return { expired, nearExpiry };
    };

    const paginatedData = (data, page) => {
        const startIndex = (page - 1) * itemsPerPage;
        return data.slice(startIndex, startIndex + itemsPerPage);
    };

    const { expired: allExpired, nearExpiry: allNearExpiry } = separateComponents(sortedNearExpirationBloods);
    const paginatedNearExpiry = paginatedData(allNearExpiry, nearExpiryPage);
    const paginatedExpired = paginatedData(allExpired, expiredPage);

    const handleNextPage = (setPage, page, totalItems) => {
        if (page * itemsPerPage < totalItems) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = (setPage, page) => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mt-5">Welcome to the Dashboard</h1>
            <p className='text-center'>This application helps manage blood inventory and tracking efficiently.</p>
            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-4">Statistics</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="bg-white p-4 rounded shadow">
                        <h3 className="font-semibold">Total Blood Records</h3>
                        <p className="text-xl">{stats.totalBlood}</p>
                    </div>
                    <div className="bg-white p-4 rounded shadow">
                        <h3 className="font-semibold">Total Orders</h3>
                        <p className="text-xl">{stats.totalOrders}</p>
                    </div>
                    <div className="bg-white p-4 rounded shadow">
                        <h3 className="font-semibold">Blood Type Counts</h3>
                        {Object.entries(stats.bloodTypeCounts).map(([type, count]) => (
                            <p key={type} className="text-xl">{type}: {count}</p>
                        ))}
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="text-2xl font-bold mb-4">Order Status Counts</h2>
                    <div className="bg-white p-4 rounded shadow">
                        {Object.entries(stats.statusCounts).map(([status, count]) => (
                            <p key={status} className="text-xl">{status}: {count}</p>
                        ))}
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="text-2xl font-bold mb-4 text-center">Blood Records Near Expiration</h2>
                    <div className="bg-white p-4 rounded shadow-md">
                        {paginatedNearExpiry.length > 0 ? (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {paginatedNearExpiry.map(component => (
                                        <div key={component._id} className={`border ${isExpired(component.expirationDate) ? 'border-red-500' : 'border-gray-300'} rounded-lg p-4 hover:shadow-lg transition-shadow duration-200`}>
                                            <h3 className="font-semibold text-lg mb-2">Bag ID: {component.bagID}</h3>
                                            <p className="text-gray-700"><strong>Expiration Date:</strong> {new Date(component.expirationDate).toLocaleDateString()}</p>
                                            <p className="text-gray-700"><strong>Blood Type:</strong> {component.bloodType}</p>
                                            <p className="text-gray-700"><strong>Component Name:</strong> {component.name}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex justify-center mt-4">
                                    <button className="px-4 py-2 mx-2 bg-gray-300 rounded" onClick={() => handlePrevPage(setNearExpiryPage, nearExpiryPage)}>Previous</button>
                                    <button className="px-4 py-2 mx-2 bg-gray-300 rounded" onClick={() => handleNextPage(setNearExpiryPage, nearExpiryPage, allNearExpiry.length)}>Next</button>
                                </div>
                            </>
                        ) : (
                            <p className="text-center text-gray-500">No blood records are near expiration.</p>
                        )}
                    </div>
                </div>

                {/* Expired Blood Records */}
                <div className="mt-10">
                    <h2 className="text-2xl font-bold mb-4 text-center">Expired Blood Records</h2>
                    <div className="bg-white p-4 rounded shadow-md">
                        {paginatedExpired.length > 0 ? (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {paginatedExpired.map(component => (
                                        <div key={component._id} className="border border-red-500 rounded-lg p-4 hover:shadow-lg transition-shadow duration-200">
                                            <h3 className="font-semibold text-lg mb-2">Bag ID: {component.bagID}</h3>
                                            <p className="text-red-500"><strong>Expiration Date:</strong> {new Date(component.expirationDate).toLocaleDateString()}</p>
                                            <p className="text-red-500"><strong>Blood Type:</strong> {component.bloodType}</p>
                                            <p className="text-red-500"><strong>Component Name:</strong> {component.name}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex justify-center mt-4">
                                    <button className="px-4 py-2 mx-2 bg-gray-300 rounded" onClick={() => handlePrevPage(setExpiredPage, expiredPage)}>Previous</button>
                                    <button className="px-4 py-2 mx-2 bg-gray-300 rounded" onClick={() => handleNextPage(setExpiredPage, expiredPage, allExpired.length)}>Next</button>
                                </div>
                            </>
                        ) : (
                            <p className="text-center text-gray-500">No expired blood records.</p>
                        )}
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="text-2xl font-bold mb-4">Blood Orders Per Week</h2>
                    <div className="bg-white p-4 rounded shadow">
                        <Bar data={ordersChartData} options={chartOptions} />
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="text-2xl font-bold mb-4">Blood Added Per Month</h2>
                    <div className="bg-white p-4 rounded shadow">
                        <Bar data={bloodAddedChartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
