import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BloodSelectionModal from './BloodSelectionModal'; // Import your modal

const OrderForm = ({ order, bloods, onClose }) => {
    const [formData, setFormData] = useState({
        trackingInfo: '',
        recipientName: '',
        contactNumber: '',
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedBloods, setSelectedBloods] = useState([]); // Store multiple selected blood bags

    // Get the admin username from local storage
    const adminUsername = localStorage.getItem('username'); // Adjust the key as necessary

    // Function to generate tracking info
    const generateTrackingInfo = () => {
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        return `${year}${month}${day}-${hours}${minutes}${seconds}`;
    };

    useEffect(() => {
        if (order) {
            // Pre-fill the form with order data
            setFormData({
                trackingInfo: order.trackingInfo,
                recipientName: order.recipient?.name || '',
                contactNumber: order.contactNumber || '',
            });
            // If the order contains bagIDs, pre-populate selectedBloods
            setSelectedBloods(order.bagID || []); // Assuming bagID is an array of blood bags
        } else {
            // Generate tracking info when creating a new order
            setFormData((prevData) => ({
                ...prevData,
                trackingInfo: generateTrackingInfo(),
            }));
        }
    }, [order]);

    const handleSelectBlood = (blood) => {
        // Check if blood.bagID is the valid MongoDB ObjectId
        const isAlreadySelected = selectedBloods.some(b => b._id === blood._id); // Check with _id instead of bagID
        
        if (isAlreadySelected) {
            alert('This blood bag has already been selected.');
            return;
        }
    
        setSelectedBloods((prevBloods) => [
            ...prevBloods,
            blood // No need for a bagID key here as we store the entire object
        ]);
        setModalOpen(false);
    };

    const removeSelectedBlood = (bloodID) => {
        setSelectedBloods((prevBloods) => prevBloods.filter(blood => blood._id !== bloodID));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Ensure all required fields are filled
        if (!formData.recipientName || !formData.contactNumber || (selectedBloods.length === 0 && !order)) {
            setSuccessMessage('Please fill all required fields and select at least one blood bag.');
            return;
        }
    
        // Ensure admin username is set
        if (!adminUsername) {
            setSuccessMessage('Admin username is required.');
            return;
        }
    
        // Prepare the bagID(s) from the selected blood bags (use _id from the blood object)
        const selectedBagIDs = selectedBloods.map(blood => blood._id); // bagID is _id from the blood bag
    
        // Prepare data to submit
        const dataToSubmit = {
            ...formData,
            recipient: {
                name: formData.recipientName,
            },
            approvalStatus: 'Pending',
            adminUsername, // Add the admin username
            bagID: selectedBagIDs.length > 0 ? selectedBagIDs : (order ? order.bagID : []), // Use existing blood bags if it's an edit
        };
    
        console.log("Submitting data:", dataToSubmit); // Log the data being submitted
    
        try {
            let response;
            if (order) {
                // Update existing order
                response = await axios.patch(`${process.env.REACT_APP_API_URL}/orders/${order._id}`, dataToSubmit);
            } else {
                // Create new order
                response = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, dataToSubmit);
            }
            setSuccessMessage(order ? 'Order updated successfully!' : 'Order created successfully!');
            setTimeout(() => {
                setSuccessMessage('');
                onClose();
            }, 3000);
        } catch (error) {
            console.error("Error submitting order:", error.response ? error.response.data : error.message);
            setSuccessMessage("Error submitting order.");
        }
    };

    return (
        <div className="relative">
            <form onSubmit={handleSubmit} className="p-6 bg-white rounded-lg shadow-md max-w-3xl mx-auto mt-16">
                <h2 className="text-2xl font-bold mb-4">{order ? 'Update Order' : 'Create Order'}</h2>

                {successMessage && (
                    <div className="mb-4 text-green-500 text-center">{successMessage}</div>
                )}

                {/* Display Selected Blood Bags */}
                <div className="mb-4">
                    <label className="block mb-2">Selected Blood Bags:</label>
                    {selectedBloods.length > 0 ? (
                        <ul>
                            {selectedBloods.map((blood, index) => (
                                <li key={index} className="mb-2">
                                    <span>Bag ID: {blood.bagID}</span>
                                    <button
                                        type="button"
                                        onClick={() => removeSelectedBlood(blood._id)}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No blood bags selected.</p>
                    )}
                </div>

                {/* Tracking Info */}
                <div className="mb-4">
                    <label className="block mb-2">
                        Tracking Info:
                        <input
                            type="text"
                            value={formData.trackingInfo}
                            onChange={(e) => setFormData({ ...formData, trackingInfo: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                {/* Recipient Name */}
                <div className="mb-4">
                    <label className="block mb-2">
                        Recipient Name:
                        <input
                            type="text"
                            value={formData.recipientName}
                            onChange={(e) => setFormData({ ...formData, recipientName: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                {/* Contact Number */}
                <div className="mb-4">
                    <label className="block mb-2">
                        Contact Number:
                        <input
                            type="text"
                            value={formData.contactNumber}
                            onChange={(e) => setFormData({ ...formData, contactNumber: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </label>
                </div>

                {/* Select Blood Button */}
                <button
  type="button"
  onClick={() => setModalOpen(true)}
  className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
>
  Select Blood Bags
</button>


                {/* Submit Button */}
                <button
                    type="submit"
                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200 w-full"
                >
                    {order ? 'Update Order' : 'Create Order'}
                </button>

                {/* Cancel Button */}
                <button
                    type="button"
                    onClick={onClose}
                    className="mt-2 ml-2 bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-200 w-full"
                >
                    Cancel
                </button>
            </form>
            {modalOpen && (
    <BloodSelectionModal
        show={modalOpen} // Pass modalOpen to show prop
        bloods={bloods}
        onClose={() => setModalOpen(false)}
        onSelectBlood={handleSelectBlood}
    />
)}


        </div>
    );
};

export default OrderForm;
