import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];

    // Generate page numbers based on the total number of pages
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        if (pageNumber !== currentPage) {
            onPageChange(pageNumber);
        }
    };

    return (
        <div className="flex justify-center items-center mt-4">
            <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Prev
            </button>

            {/* Display the page numbers */}
            {pageNumbers.map((number) => (
                <button
                    key={number}
                    onClick={() => handlePageClick(number)}
                    className={`px-4 py-2 mx-1 rounded-md ${
                        number === currentPage
                            ? 'bg-red-600 text-white'
                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                >
                    {number}
                </button>
            ))}

            <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md ml-2"
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;
