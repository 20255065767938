import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ManageAdmins = () => {
    const [admins, setAdmins] = useState([]);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        fetchAdmins();
    }, []);

    const fetchAdmins = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admins`);
            setAdmins(response.data);
        } catch (error) {
            console.error("Error fetching admins:", error);
        }
    };

    const handleAddAdmin = async () => {
        const confirmAdd = window.confirm("Are you sure you want to add this admin?");
        if (!confirmAdd) return;

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/admins`, { name, username, password });
            resetForm();
            fetchAdmins();
            showMessage("Admin added successfully!", "success");
            setIsAddModalOpen(false);
        } catch (error) {
            showMessage("Error adding admin: " + error.message, "error");
            console.error("Error adding admin:", error);
        }
    };

    const handleEditAdmin = async () => {
        const confirmEdit = window.confirm("Are you sure you want to save these changes?");
        if (!confirmEdit) return;

        try {
            const payload = {
                name: selectedAdmin.name,
                username: newUsername || selectedAdmin.username,
                password: newPassword || selectedAdmin.password
            };

            await axios.patch(`${process.env.REACT_APP_API_URL}/admins/${selectedAdmin._id}`, payload);
            setIsEditModalOpen(false);
            resetForm();
            fetchAdmins();
            showMessage("Admin updated successfully!", "success");
        } catch (error) {
            showMessage("Error updating admin: " + error.message, "error");
            console.error("Error updating admin:", error);
        }
    };

    const handleDeleteAdmin = async (adminId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this admin?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/admins/${adminId}`);
            fetchAdmins();
            showMessage("Admin deleted successfully!", "success");
        } catch (error) {
            showMessage("Error deleting admin: " + error.message, "error");
            console.error("Error deleting admin:", error);
        }
    };

    const showMessage = (msg, type) => {
        setMessage(msg);
        setMessageType(type);
        setTimeout(() => {
            setMessage('');
            setMessageType('');
        }, 3000);
    };

    const resetForm = () => {
        setName('');
        setUsername('');
        setPassword('');
        setNewUsername('');
        setNewPassword('');
        setSelectedAdmin(null);
    };

    return (
        <div className="container mx-auto p-6">
            {message && (
                <div className={`fixed top-0 left-1/2 transform -translate-x-1/2 p-4 mb-4 rounded ${messageType === "success" ? "bg-green-500 text-white" : "bg-red-500 text-white"}`}>
                    {message}
                </div>
            )}

            <h1 className="text-4xl font-bold text-center text-red-600 mb-6">Manage Admins</h1>
            
            <div className="flex justify-center mb-6">
                <button
                    onClick={() => setIsAddModalOpen(true)}
                    className="bg-red-600 text-white py-2 px-4 rounded shadow hover:bg-red-500 transition"
                >
                    Add Admin
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                    <thead>
                        <tr className="bg-red-600 text-white">
                            <th className="py-3 px-4 border-b text-left">Name</th>
                            <th className="py-3 px-4 border-b text-left">Username</th>
                            <th className="py-3 px-4 border-b text-left">Role</th> {/* New column for Role */}
                            <th className="py-3 px-4 border-b text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map(admin => (
                            <tr key={admin._id} className="hover:bg-gray-100">
                                <td className="py-3 px-4 border-b text-left">{admin.name}</td>
                                <td className="py-3 px-4 border-b text-left">{admin.username}</td>
                                <td className="py-3 px-4 border-b text-left">{admin.role}</td> {/* Displaying Role */}
                                <td className="py-3 px-4 border-b text-left">
                                    <button
                                        onClick={() => {
                                            setSelectedAdmin(admin);
                                            setNewUsername('');
                                            setNewPassword('');
                                            setIsEditModalOpen(true);
                                        }}
                                        className="bg-blue-600 text-white py-1 px-2 rounded mr-2 hover:bg-blue-500 transition"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDeleteAdmin(admin._id)}
                                        className="bg-red-600 text-white py-1 px-2 rounded hover:bg-red-500 transition"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Add Admin Modal */}
            {isAddModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <h2 className="text-2xl font-bold mb-4">Add New Admin</h2>
                        <input
                            type="text"
                            placeholder="Name"
                            className="border rounded p-2 mb-2 w-full"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Username"
                            className="border rounded p-2 mb-2 w-full"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            className="border rounded p-2 mb-4 w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            onClick={handleAddAdmin}
                            className="bg-green-600 text-white py-2 px-4 rounded mr-2 hover:bg-green-500 transition"
                        >
                            Add Admin
                        </button>
                        <button
                            onClick={() => setIsAddModalOpen(false)}
                            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-500 transition"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}

            {/* Edit Admin Modal */}
            {isEditModalOpen && selectedAdmin && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <h2 className="text-2xl font-bold mb-4">Edit Admin: {selectedAdmin.username}</h2>
                        <input
                            type="text"
                            placeholder="New Username"
                            className="border rounded p-2 mb-2 w-full"
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="New Password"
                            className="border rounded p-2 mb-4 w-full"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button
                            onClick={handleEditAdmin}
                            className="bg-green-600 text-white py-2 px-4 rounded mr-2 hover:bg-green-500 transition"
                        >
                            Save Changes
                        </button>
                        <button
                            onClick={() => setIsEditModalOpen(false)}
                            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-500 transition"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageAdmins;
