import React, { useState } from 'react';
import axios from 'axios';

const Tracking = () => {
    const [trackingInfo, setTrackingInfo] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tracking/${trackingInfo}`);
            if (response.data) {
                setResult(response.data);
            }
        } catch (err) {
            setError('Unable to fetch the order status. Please check your input.');
            console.error("Error fetching order status:", err);
        }
    };

    return (
        <div className="container mx-auto p-4 md:p-8">
      <header className="flex items-center justify-between mb-6">
    <div className="logo">
        <a href="/">
            <img src="/logo.png" alt="Company Logo" className="h-12" />
        </a>
    </div>
</header>


            <h1 className="text-center text-3xl pt-10 font-bold text-red-600">Blood Tracking System</h1>
            <form onSubmit={handleSubmit} className="mt-6 bg-white p-6 rounded-lg shadow-lg space-y-4">
                <div>
                    <label className="block text-gray-700 text-lg">Tracking Info:</label>
                    <input
                        type="text"
                        value={trackingInfo}
                        onChange={(e) => setTrackingInfo(e.target.value)}
                        className="border border-gray-300 rounded p-3 w-full focus:ring focus:ring-red-300 transition"
                        placeholder="Enter Tracking Info"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-red-600 text-white py-3 rounded-lg hover:bg-red-700 transition"
                >
                    Track
                </button>
            </form>

            {error && (
                <div className="mt-4 text-red-500 text-lg text-center">{error}</div>
            )}

{result && (
    <div className="mt-6 p-4 border rounded-lg shadow-md bg-gray-50">
        <h2 className="text-xl font-semibold text-gray-800">Tracking Result</h2>
        <table className="min-w-full mt-4 bg-white border border-gray-300">
            <thead>
                <tr className="bg-gray-200">
                    <th className="p-2 text-left border">Information</th>
                    <th className="p-2 text-left border">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="p-2 border">Recipient Name</td>
                    <td className="p-2 border">{result.order.recipient.name}</td>
                </tr>
                <tr>
                    <td className="p-2 border">Blood Type</td>
                    <td className="p-2 border">{result.blood.bloodType}</td>
                </tr>
                <tr>
                    <td className="p-2 border">Volume</td>
                    <td className="p-2 border">{result.blood.volume} mL</td>
                </tr>
                <tr>
                    <td className="p-2 border">Quantity</td>
                    <td className="p-2 border">{result.order.quantity}</td>
                </tr>
                <tr>
                    <td className="p-2 border">Order Status</td>
                    <td className="p-2 border">{result.order.deliveryStatus}</td>
                </tr>
            </tbody>
        </table>
    </div>
)}


            {/* Footer Section */}
            <footer className="mt-10 p-4 bg-gray-200 rounded-lg text-center">
                <h3 className="font-bold text-lg">Philippine Red Cross National Headquarters</h3>
                <p>Address: 37 EDSA, corner Boni Ave, Mandaluyong, 1550</p>
                <p>Hours: Open 24 hours</p>
                <p>Phone: (02) 8790 2300</p>
            </footer>
        </div>
    );
};

export default Tracking;
