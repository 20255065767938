import React, { useState, useEffect } from 'react';

const BloodSelectionModal = ({ show, onClose, bloods, onSelectBlood }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredBloods, setFilteredBloods] = useState([]);
    const [selectedQuantity, setSelectedQuantity] = useState(1); // State for user input quantity

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        
        // Filter bloods by the search term and exclude bloods with 'completed' status
        const results = bloods.filter(blood =>
            (blood.bagID.toLowerCase().includes(term) || blood.bloodType.toLowerCase().includes(term)) &&
            blood.quantity > 0 && blood.status !== 'completed' // Exclude completed blood bags
        );
        setFilteredBloods(results);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('keydown', handleKeyDown);
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [show]);

    useEffect(() => {
        // Filter initial bloods list to exclude 'completed' bloods and those with no quantity
        const results = bloods.filter(blood => blood.quantity > 0 && blood.status !== 'completed');
        setFilteredBloods(results);
    }, [bloods]);

    if (!show) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-6 max-w-lg w-full">
                <h2 className="text-2xl font-bold mb-4">Select Blood</h2>
                <input
                    type="text"
                    placeholder="Search by Bag ID or Blood Type"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="border border-gray-300 rounded p-2 w-full mb-4"
                />
                <ul className="max-h-60 overflow-y-auto border border-gray-300 rounded">
                    {filteredBloods.length > 0 ? (
                        filteredBloods.map(blood => (
                            <li
                                key={blood.bagID}
                                className="cursor-pointer p-2 hover:bg-gray-100"
                            >
                                {blood.bagID} - {blood.bloodType} 
                                <button
                                    onClick={() => onSelectBlood(blood, selectedQuantity)}
                                    className="ml-2 bg-blue-500 text-white py-1 px-2 rounded"
                                >
                                    Select
                                </button>
                            </li>
                        ))
                    ) : (
                        <li className="p-2 text-gray-500 text-center">No blood bags available</li>
                    )}
                </ul>
                <button
                    type="button"
                    onClick={onClose}
                    className="mt-4 bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default BloodSelectionModal;
