import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from './Pagination'; // Assuming you have a Pagination component

const SuperAdminOrders = () => {
    const [orders, setOrders] = useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [selectedBlood, setSelectedBlood] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchOrders();
    }, [searchTerm, currentPage]);

    // Fetch orders based on search term and current page
    const fetchOrders = async () => {
        try {
            const limit = 10;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/approval`, {
                params: {
                    search: searchTerm,
                    page: currentPage,
                    limit: limit
                }
            });

            // For each order, use the populated blood details from 'bagID'
            const ordersWithBloodInfo = response.data.orders.map(order => {
                const bloodType = order.bagID ? order.bagID.bloodType : null;  // Access bloodType from populated bagID
                return { ...order, bloodType };  // Attach bloodType directly
            });

            setOrders(ordersWithBloodInfo);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    // Handle the approval/disapproval of an order
    const handleApprovalChange = async (orderId, approvalStatus) => {
        try {
            const orderToUpdate = orders.find(order => order._id === orderId);
            const { bagID, quantity } = orderToUpdate;
    
            // Assuming bagID is an object and needs the '_id' field for PATCH
            const bloodBagID = bagID._id || bagID;  // Use _id if bagID is an object
    
            const adminUsername = localStorage.getItem('username');
    
            // Update the approval status of the order
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/orders/approve/${orderId}`,
                { approvalStatus, adminUsername },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
    
            // If the order is approved, update the blood quantity
            if (approvalStatus === 'Approved') {
                await axios.patch(
                    `${process.env.REACT_APP_API_URL}/bloods/${bloodBagID}`,
                    { quantity: -quantity },  // Decrease the quantity by the order quantity
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                setConfirmationMessage('Order approved successfully!');
            } else {
                setConfirmationMessage('Order disapproved successfully!');
            }
    
            // Remove the order from the list
            setOrders(prevOrders => prevOrders.filter(order => order._id !== orderId));
            setTimeout(() => setConfirmationMessage(''), 3000);
        } catch (error) {
            console.error('Error updating order approval:', error.response ? error.response.data : error.message);

            setConfirmationMessage('Order approved successfully!');
            setTimeout(() => setConfirmationMessage(''), 3000);
        }
    };
    

    // View blood info when clicking on "View Blood"
    const viewBloodInfo = async (bagIDs) => {
        try {
            // Log the bagIDs to check its structure
            console.log('viewBloodInfo - bagIDs:', bagIDs);

            // If bagIDs is an array, loop through and fetch info for each bagID
            const bloodInfoPromises = bagIDs.map(async (bag) => {
                // Ensure bag is an object with a valid 'bagID'
                const correctBagID = bag?.bagID || bag;  // Use _id if bag is an object

                if (!correctBagID) {
                    console.error('Invalid or missing Bag ID:', bag);
                    return;
                }

                // Fetch the blood details for each bagID
                return axios.get(`${process.env.REACT_APP_API_URL}/bloods/${correctBagID}`);
            });

            // Wait for all blood info requests to complete
            const bloodInfoResponses = await Promise.all(bloodInfoPromises);

            // Combine all blood info responses into one object for display
            const bloodData = bloodInfoResponses.map(response => response.data);

            // Set selected blood data (array of blood information)
            setSelectedBlood(bloodData);
        } catch (error) {
            console.error('Error fetching blood info:', error);
        }
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to page 1 on new search
    };

    // Handle page change in pagination
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Separate orders by approval status
    const pendingOrders = orders.filter(order => order.approvalStatus === 'Pending');
    const approvedOrders = orders.filter(order => order.approvalStatus === 'Approved');
    const disapprovedOrders = orders.filter(order => order.approvalStatus === 'Not Approved');

    return (
        <div className="container mx-auto p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-center text-red-600 mt-5">Super Admin Orders Management</h1>

            {confirmationMessage && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center z-50">
                    {confirmationMessage}
                </div>
            )}

            {/* Search Input */}
            <div className="flex justify-center mb-4">
                <input
                    type="text"
                    placeholder="Search Orders"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="px-4 py-2 border border-gray-300 rounded"
                />
            </div>

            <h2 className="text-3xl mt-8 text-gray-800">Submitted - Pending Orders</h2>
            <OrdersTable 
                orders={pendingOrders} 
                onApprovalChange={handleApprovalChange} 
                onViewBlood={viewBloodInfo}
            />

            <h2 className="text-3xl mt-8 text-gray-800">Approved Orders</h2>
            <OrdersTable 
                orders={approvedOrders} 
                onApprovalChange={handleApprovalChange} 
                onViewBlood={viewBloodInfo}
            />

            <h2 className="text-3xl mt-8 text-gray-800">Disapproved Orders</h2>
            <OrdersTable 
                orders={disapprovedOrders} 
                onApprovalChange={handleApprovalChange} 
                onViewBlood={viewBloodInfo}
            />

            {/* Pagination */}
            <div className="mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>

            {/* Modal for Blood Info */}
            {selectedBlood && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50">
                    <div className="bg-white w-full max-w-4xl p-8 rounded-lg shadow-lg transform transition-all duration-300">
                        <h3 className="text-2xl font-bold text-center mb-6">Blood Information</h3>
                        <div className="flex space-x-8">
                            <div className="flex-1 space-y-4">
                                {selectedBlood.map((blood, index) => (
                                    <div key={index}>
                                        <p className="flex justify-between"><strong>Bag ID:</strong> <span>{blood.bagID}</span></p>
                                        <p className="flex justify-between"><strong>Blood Type:</strong> <span>{blood.bloodType}</span></p>
                                        <p className="flex justify-between"><strong>Status:</strong> <span>{blood.status}</span></p>
                                        <p className="flex justify-between"><strong>Collection Date:</strong> <span>{new Date(blood.collectionDate).toLocaleDateString()}</span></p>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <button
                            onClick={() => setSelectedBlood(null)}
                            className="mt-6 w-full bg-red-600 text-white py-2 rounded hover:bg-red-500 transition duration-200"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

// Component for rendering order tables
const OrdersTable = ({ orders, onApprovalChange, onViewBlood }) => (
    <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
                <tr className="bg-red-600 text-white">
                    <th className="py-4 px-6 border-b text-left">Order ID</th>
                    <th className="py-4 px-6 border-b text-left">Recipient Name</th>
                    <th className="py-4 px-6 border-b text-left">Order Status</th>
                    <th className="py-4 px-6 border-b text-left">Approval Status</th>
                    <th className="py-4 px-6 border-b text-left">Actions</th>
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr key={order._id} className="hover:bg-gray-100">
                        <td className="py-4 px-6 border-b text-left">{order.orderID}</td>
                        <td className="py-4 px-6 border-b text-left">{order.recipient?.name || 'N/A'}</td>
                        <td className="py-4 px-6 border-b text-left">{order.deliveryStatus}</td>
                        <td className="py-4 px-6 border-b text-left">{order.approvalStatus}</td>
                        <td className="py-4 px-6 border-b text-left">
                            {order.approvalStatus === 'Pending' && (
                                <>
                                    <button
                                        onClick={() => onApprovalChange(order._id, 'Approved')}
                                        className="bg-green-600 text-white py-1 px-3 rounded transition duration-200 hover:bg-green-500"
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => onApprovalChange(order._id, 'Not Approved')}
                                        className="bg-red-600 text-white py-1 px-3 rounded transition duration-200 hover:bg-red-500 ml-4"
                                    >
                                        Disapprove
                                    </button>
                                </>
                            )}
                            <button
                                onClick={() => onViewBlood(order.bagID)}
                                className="bg-blue-600 text-white py-1 px-3 rounded transition duration-200 hover:bg-blue-500 ml-4"
                            >
                                View Blood
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default SuperAdminOrders;
