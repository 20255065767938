import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OrderForm from './OrderForm';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [bloods, setBloods] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOrderFormOpen, setIsOrderFormOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('pending');
    const [selectedBlood, setSelectedBlood] = useState(null);
    const [isBloodModalOpen, setIsBloodModalOpen] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const adminUsername = localStorage.getItem('username'); // Adjust the key as necessary

    useEffect(() => {
        const role = localStorage.getItem('role'); // Get the role from localStorage
        setUserRole(role);
        fetchOrders();
        fetchBloods();
    }, []);

    const fetchUserRole = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-role`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}` 
                }
            });
            setUserRole(response.data.role);
        } catch (error) {
            console.error("Error fetching user role:", error);
        }
    };

    useEffect(() => {
        setFilteredOrders(
            orders.filter(order =>
                order.bagID.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.recipient?.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, orders]);

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders`);
            setOrders(response.data);
            setFilteredOrders(response.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const fetchBloods = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bloods`);
            setBloods(response.data);
        } catch (error) {
            console.error("Error fetching bloods:", error);
        }
    };

    const openOrderForm = (order) => {
        if (userRole === 'superadmin' || userRole === 'admin') {
            setSelectedOrder(order);
            setIsOrderFormOpen(true);
        }
    };

    const closeOrderForm = () => {
        setIsOrderFormOpen(false);
        setSelectedOrder(null);
        fetchOrders();
    };

    const sendSMS = async (order) => {
        const statusMessages = {
            'Order Received': `Hi! ${order.recipient?.name || 'Customer'}, Your order has been received.`,
            'Packed': `Hi! ${order.recipient?.name || 'Customer'}, Your order is now packed.`,
            'Ready for Pickup': `Hi! ${order.recipient?.name || 'Customer'}, Your order is ready for pickup.`,
            'Received': `Hi! ${order.recipient?.name || 'Customer'}, Your order has been successfully picked up.`,
            'Canceled': `Hi! ${order.recipient?.name || 'Customer'}, Your order has been canceled.`
        };

        const message = statusMessages[order.deliveryStatus] || 'Hi! Your order status has been updated.';

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/send-sms`, {
                number: order.contactNumber,
                message: message,
                senderName: 'Thesis',
            });
            setConfirmationMessage('SMS sent successfully!');
            setTimeout(() => setConfirmationMessage(''), 3000);
        } catch (error) {
            console.error("Error sending SMS:", error);
            setConfirmationMessage('Failed to send SMS. Please try again.');
            setTimeout(() => setConfirmationMessage(''), 3000);
        }
    };

    const handleDeleteOrder = async (orderId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this order?");
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/orders/${orderId}`);
                setConfirmationMessage('Order deleted successfully!');
                setTimeout(() => setConfirmationMessage(''), 3000);
                fetchOrders();
            } catch (error) {
                console.error("Error deleting order:", error);
            }
        }
    };

    const handleStatusChange = async (orderId, updatedStatus) => {
        try {
            const dataToSubmit = {
                deliveryStatus: updatedStatus,
                adminUsername,// Replace this with your actual variable holding the admin username
            };
    
            await axios.patch(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, dataToSubmit);
            setConfirmationMessage(`Order status updated to "${updatedStatus}" successfully!`);
            setTimeout(() => setConfirmationMessage(''), 3000);
            fetchOrders();
            const updatedOrder = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`);
            await sendSMS(updatedOrder.data);
        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };
    

    const pendingOrders = filteredOrders.filter(order => order.approvalStatus !== "Approved" && order.deliveryStatus !== "Canceled");
    const approvedOrders = filteredOrders.filter(order => order.approvalStatus === "Approved" && order.deliveryStatus !== "Canceled" && order.deliveryStatus !== "Received");
    const receivedOrders = filteredOrders.filter(order => order.deliveryStatus === "Received");
    const disapprovedOrders = filteredOrders.filter(order => order.approvalStatus !== "Approved" && order.deliveryStatus === "Canceled");

    const viewBlood = async (bagID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bloods/${bagID}`);
            setSelectedBlood(response.data);
            setIsBloodModalOpen(true);
        } catch (error) {
            console.error("Error fetching blood details:", error);
        }
    };

    return (
        <div className="container mx-auto p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-center text-red-600 mt-5">Orders Management</h1>
            <div className="text-center mt-5">
                <button onClick={() => openOrderForm(null)} className="bg-red-600 text-white py-3 px-6 rounded-full shadow-lg hover:bg-red-500 transition">
                    Add Order
                </button>
            </div>

            {confirmationMessage && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white p-4 text-center">
                    {confirmationMessage}
                </div>
            )}

            <div className="flex justify-around mt-8">
                <button onClick={() => setActiveTab('pending')} className={`px-4 py-2 ${activeTab === 'pending' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Pending Approval</button>
                <button onClick={() => setActiveTab('approved')} className={`px-4 py-2 ${activeTab === 'approved' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Approved Orders</button>
                <button onClick={() => setActiveTab('received')} className={`px-4 py-2 ${activeTab === 'received' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Past Orders (Received)</button>
                <button onClick={() => setActiveTab('disapproved')} className={`px-4 py-2 ${activeTab === 'disapproved' ? 'bg-red-600 text-white' : 'text-red-600'}`}>Disapproved Orders</button>
            </div>

            {activeTab === 'pending' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Pending Orders</h2>
                    <OrdersTable 
                        orders={pendingOrders}
                        onStatusChange={handleStatusChange}
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                        showStatus={false}
                        onViewBlood={viewBlood}
                    />
                </>
            )}

            {activeTab === 'approved' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Approved Orders</h2>
                    <OrdersTable 
                        orders={approvedOrders} 
                        onStatusChange={handleStatusChange}
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                        showStatus={true} 
                        onViewBlood={viewBlood} 
                        onSendSMS={sendSMS} // Added this line
                    />
                </>
            )}

            {activeTab === 'received' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Received Orders</h2>
                    <OrdersTable 
                        orders={receivedOrders} 
                        onEdit={null}
                        onDelete={null}
                        showStatus={false} 
                        isEditable={false} 
                        onViewBlood={viewBlood} 
                    />
                </>
            )}

            {activeTab === 'disapproved' && (
                <>
                    <h2 className="text-3xl mt-8 text-gray-800">Disapproved Orders</h2>
                    <OrdersTable 
                        orders={disapprovedOrders} 
                        onEdit={userRole === 'superadmin' ? openOrderForm : null}
                        onDelete={userRole === 'superadmin' ? handleDeleteOrder : null}
                        showStatus={false} 
                        onViewBlood={viewBlood} 
                    />
                </>
            )}

            {isOrderFormOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <OrderForm 
                            order={selectedOrder} 
                            bloods={bloods} 
                            onClose={closeOrderForm} 
                        />
                    </div>
                </div>
            )}
            {isBloodModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/3">
                        <h2 className="text-xl font-bold">Blood Details</h2>
                        <p><strong>Bag ID:</strong> {selectedBlood.bagID}</p>
                        <p><strong>Type:</strong> {selectedBlood.bloodType || 'N/A'}</p>
                        <p><strong>Volume:</strong> {selectedBlood.volume || 'N/A'} mL</p>
                        <p><strong>Collection Date:</strong> {new Date(selectedBlood.collectionDate).toLocaleDateString() || 'N/A'}</p>
                        <p><strong>Status:</strong> {selectedBlood.status || 'N/A'}</p>
                        <p><strong>Quantity:</strong> {selectedBlood.quantity || 'N/A'}</p>

                        {selectedBlood.components && selectedBlood.components.length > 0 && (
                            <div className="mt-4">
                                <strong>Components:</strong>
                                <ul className="list-disc pl-5">
                                    {selectedBlood.components.map((component, index) => (
                                        <li key={index}>
                                            {component.name} - {component.included ? `${component.volume || 'N/A'} mL` : 'N/A units'} 
                                            {component.included && component.expirationDate && (
                                                <span> (Expires: {new Date(component.expirationDate).toLocaleDateString()})</span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <button onClick={() => setIsBloodModalOpen(false)} className="mt-4 bg-red-600 text-white py-2 px-4 rounded">Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

const OrdersTable = ({ orders, onStatusChange, onEdit, onDelete, showStatus, onViewBlood, onSendSMS }) => (
    <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
                <tr className="bg-red-600 text-white">
                    <th className="py-4 px-6 border-b text-left">Order ID</th> {/* New column for Order ID */}
                    <th className="py-4 px-6 border-b text-left">Blood Bag ID</th>
                    <th className="py-4 px-6 border-b text-left">Quantity</th>
                    <th className="py-4 px-6 border-b text-left">Tracking Info</th>
                    <th className="py-4 px-6 border-b text-left">Recipient Name</th>
                    <th className="py-4 px-6 border-b text-left">Contact Number</th>
                    {showStatus && <th className="py-4 px-6 border-b text-left">Order Status</th>}
                    {showStatus && <th className="py-4 px-6 border-b text-left">Actions</th>}
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr key={order._id} className="hover:bg-gray-100">
                        <td className="py-4 px-6 border-b text-left">{order.orderID}</td> {/* Displaying Order ID */}
                        <td className="py-4 px-6 border-b text-left">{order.bagID}</td>
                        <td className="py-4 px-6 border-b text-left">{order.quantity || 'N/A'}</td>
                        <td className="py-4 px-6 border-b text-left">{order.trackingInfo}</td>
                        <td className="py-4 px-6 border-b text-left">{order.recipient?.name || 'N/A'}</td>
                        <td className="py-4 px-6 border-b text-left">{order.contactNumber || 'N/A'}</td>
                        {showStatus && (
                            <>
                                <td className="py-4 px-6 border-b text-left">
                                    <select
                                        value={order.deliveryStatus}
                                        onChange={(e) => onStatusChange(order._id, e.target.value)}
                                        className="border rounded-lg p-1"
                                    >
                                        <option value="Order Received">Order Received</option>
                                        <option value="Packed">Packed</option>
                                        <option value="Ready for Pickup">Ready for Pickup</option>
                                        <option value="Received">Received</option>
                                        <option value="Canceled">Canceled</option>
                                    </select>
                                </td>
                                <td className="py-4 px-6 border-b text-left">
                                    {onEdit && (
                                        <button 
                                            onClick={() => onEdit(order)} 
                                            className="text-white bg-blue-600 hover:bg-blue-700 py-1 px-3 rounded transition duration-200"
                                        >
                                            Edit
                                        </button>
                                    )}
                                    {onDelete && (
                                        <button 
                                            onClick={() => onDelete(order._id)} 
                                            className="text-white bg-red-600 hover:bg-red-700 py-1 px-3 rounded transition duration-200 ml-4"
                                        >
                                            Delete
                                        </button>
                                    )}
                                    <button 
                                        onClick={() => onViewBlood(order.bagID)} 
                                        className="text-white bg-green-600 hover:bg-green-700 py-1 px-3 rounded transition duration-200 ml-4"
                                    >
                                        View Blood
                                    </button>
                                    {onSendSMS && (
                                        <button 
                                            onClick={() => onSendSMS(order)} 
                                            className="text-white bg-yellow-600 hover:bg-yellow-700 py-1 px-3 rounded transition duration-200 ml-4"
                                        >
                                            Send SMS
                                        </button>
                                    )}
                                </td>
                            </>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);



export default Orders;
